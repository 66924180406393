import client from "./client";

export default {
    async getCourses() {
        return client.parseResponse(await client.get('/courses'))
    },

    async getCourse(id){
        return client.parseResponse(await client.get('/courses/details',id))
    },

    async getUserCourses() {
        return client.parseResponse(await client.get('/user-courses'))
    },

    async getUserCourse(id){
        return client.parseResponse(await client.get('/user-courses/details',id))
    },

    async getUserCourseModules(id){
        return client.parseResponse(await client.get('/usercourse-modules/details',id))
    },

    async courseCheckout(credentials){
        return client.parseResponse(await client.post('/courses/checkout',credentials))
    }
}

