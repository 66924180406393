/* eslint-disable class-methods-use-this */
import axios from 'axios';
import _ from 'lodash';
import {getFromLocalStorage} from "../utils/local-storage";


class paymentClient {
    //The constructor, attributes definitions
    constructor(){
        //Setting the header variables
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['x-api-key']= 'Bearer xt09FSgLsyO7W9tgRM4oTUQH1SGPFgJFUFOcPc69VwBUc2KoBEoHHs4fZ0TBEfZH';

        const meta = document.head.querySelector('meta[name="csrf-token"]');
        if (meta) {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = meta.content;
        }


        const token = getFromLocalStorage('token') //Getting the user token

        if (token) {
            // Token exists
            axios.defaults.headers.common.Authorization = "";
        } 


        //creating the base url
        this.http = axios.create({
            baseURL : "https://payments.ujani.co.ke/api/v1"    //local
        });


        this.http.interceptors.response.use(response => response, async (error) => {
            if (error.response && error.response.status === 401) {
                // localStorage.removeItem('app_token');
                if (!window.location.href.includes("auth") && !window.location.href.includes("register")) {
                    window.location.href = '/auth/login';
                }
            }
            return Promise.reject(error);
        });
    }

      
    //The functions
    get(path, params, config) {
        return this.http.get(path, {params, ...config});
    }

    post(path, data, config) {
        return this.http.post(path, data, config);
    }

    upload(path, data, file, config) {
        const form = new FormData();
        _.each(data, (value, key) => {
            form.append(key, value);
        });
        return this.post(path, form, {
            ...config,
            headers: {'Content-Type': 'multipart/form-data'},
        });
    }

    put(path, data, config) {
        return this.http.put(path, data, config);
    }

    delete(path, params, config) {
        return this.http.delete(path, {params, ...config});
    }

    parseResponse({data}) {
        return data;
    }
}

export default new paymentClient();
