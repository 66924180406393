import React, { useState,useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { invokeStk } from '../../store/payments.store';

const Checkout = () => {
  const [msisdn, setMsisdn] = useState('');
  const dispatch = useDispatch();

  // useEffect(() => {
  //   // Reload the page when the component mounts
  //   window.location.reload();
  // }, [window.location.pathname]); 

  const handleInputChange = (event) => {
    setMsisdn(event.target.value);
  };

  const handleCheckout = (msisdn) => {
    const payload = {
      "amount": 1,
      bill_reference_number: "6K0ZT3",
      action: "STK_PUSH",
      recipient_phone: msisdn
    }

    dispatch(invokeStk(payload))
  }

  return (
    <div className='checkout'>
      <h2 className='text-center mt-3'><strong>Checkout</strong></h2>
      <div className='d-flex mt-3'>
        <nav aria-label="breadcrumb" className='text-center mx-auto'>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><Link to="/dashboard" className=''>Dashboard</Link></li>
            <li class="breadcrumb-item active">Checkout</li>
          </ol>
        </nav>
      </div>

      <div className='instructions p-4 mx-1 mt-3'>
        <p><span className='start'>
          <img
            src='../assets/images/mpesa.png'
            alt='Ujani logo'
            // className='card-img'
            width='90em'
          /></span><strong className='end'>Lipa na Mpesa</strong></p>
        <p className='mb-0'><strong style={{ color: "#253E90" }}>Instructions</strong></p>

        <ol>
          <li>Enter your M-pesa number below and a payment request will be sent to your phone</li>
          <li>Enter your M-pesa pin and send</li>
          <li>Once you have received a confirmation. <strong style={{ color: "#253E90" }}>Click complete button</strong></li>
        </ol>

        <p><strong style={{ color: "#253E90" }}>Phone Number</strong></p>
        <div className='phone px-1 text-center rounded-pill'
          style={{
            borderRadius: "5px solid red"
          }}>
          <img
            src='../assets/images/kenya.png'
            alt='Ujani logo'
            className='mr-2'
            width='35em'
          />
          <input type='number' placeholder='Enter your phone number'
            value={msisdn}
            onChange={handleInputChange}

            style={{
              border: "none"
            }} className='' />
        </div>


        <button className='btn w-100 rounded-pill mt-5' onClick={() => handleCheckout(msisdn)}>Complete</button>


      </div>

      {/* <div className='payment'>
        <div className='visa mx-1 mt-3 p-2'>
          <p className='px-0'><span className='start'>
            <img
              src='../assets/images/visa.png'
              alt='Ujani logo'
              className='mr-2'
              width='40em'
            />
            4855 ********</span>  <span className='end'>04/24</span></p>
        </div>

        <div className='visa mx-1 mt-3 p-2'>
          <p><span className='start'>
            <img
              src='../assets/images/orange.png'
              alt='Ujani logo'
              className='mr-2'
              width='40em'
            />
            4855 ******** </span><span className='end'>04/24</span></p>
        </div>

        <div className='visa mx-1 mt-3 p-2'>
          <h6 className='text-center'>
            <img
              src='../assets/images/CreditCard.png'
              alt='Ujani logo'
              className='mr-2'
              width='35em'
            />
            New Payment Cards</h6>
        </div>

        <div class="form-check m-2">
          <input type="checkbox" class="form-check-input" id="exampleCheck1"
            style={{
              backgroundColor: "green"
            }}
          />
          <p className='mx-2'><small>Remember this payment method</small></p>
        </div>

        <div className='px-2'>
          <button className='btn w-100 rounded-pill' onClick={() => handleCheckout(msisdn)}>Complete</button>
        </div>

      </div> */}


    </div>
  )
}

export default Checkout;
