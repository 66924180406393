import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userCourseModule } from '../../store/courses.store';
import Loader from '../../utils/Loader';

const MyCourseModule = () => {
  const course_details = useSelector((state) => state?.courses?.myCourse);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [module, setModule] = useState(0);

  const user_module_details = useSelector((state) => state?.courses?.myCourseModule);

  

  const moduleIds = course_details?.course_modules?.map((course_module) => course_module?.id) || [];

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.ctrlKey && event.key === 's') {
  //       alert('Screenshot detected! Please do not take screenshots of this page.');
  //       event.preventDefault();
  //     }
  //   };

  //   const handleScreenshot = () => {
  //     alert('Screenshot detected! Please do not take screenshots of this page.');
  //   };

  //   // Add event listener to prevent screenshots
  //   document.addEventListener('keydown', handleKeyDown);

  //   // Attempt to prevent screenshots on different operating systems
  //   const userAgent = window.navigator.userAgent.toLowerCase();

  //   if (userAgent.includes('android') || userAgent.includes('linux')) {
  //     // Android or Linux
  //     document.addEventListener('contextmenu', handleScreenshot);
  //   } else if (userAgent.includes('mac os')) {
  //     // Mac OS
  //     document.addEventListener('keydown', handleScreenshot);
  //   } else if (userAgent.includes('win')) {
  //     // Windows
  //     document.addEventListener('keydown', handleScreenshot);
  //   }

  //   // Clean up the event listeners when the component is unmounted
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //     document.removeEventListener('contextmenu', handleScreenshot);
  //   };
  // }, []);

  useEffect(() => {
    dispatch(userCourseModule(moduleIds[module]));

    if (module < moduleIds?.length && module >= 0) {
      dispatch(userCourseModule({ id: moduleIds[module] }));
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });

  }, [module]);

  const handlePrevious = () => {
    if (module > 0) {
      setModule((prevModule) => prevModule - 1);
    }else{
      alert("Modules Exhausted")
    }
  };

  const handleNext = () => {
    if (module < moduleIds.length - 1) {
      setModule((prevModule) => prevModule + 1);
    }else{
      alert("Modules Exhausted")
    }
  };

  const ContentWithLineBreaks = ({ content }) => {
    const contentWithLineBreaks = content
      ? content.split('\n•').map((part, index) => (
          <React.Fragment key={index}>
            {index > 0 && <br />}
            {index > 0 ? ' ' : ''} 
            <span style={{ fontWeight:"bolder", marginRight:".5em" }}>{index + 1}.</span> 
            {part.trim()}
          </React.Fragment>
        ))
      : null;
  
    return <p style={{ lineHeight: '1.2', letterSpacing: '1.2' }}>{contentWithLineBreaks}</p>;
  };

  
  return (
    <div>
    {course_details ? (<div>

        <p className='text-start py-2 ml-5'><div className=" mt-2" onClick={() => navigate(-1)}>
            <img
                src='../assets/images/carbon.png'
                alt='close'
                style={{ width: "1.5em" }}></img>
            <strong className='ml-2'>Back</strong>
        </div></p>
        <img
            src='../assets/images/rectangle_9.png'
            alt='Ujani logo'
            style={{
                width: "100%",
                height: "30vh"
            }}>
        </img>
        <div className='course'>


            <div className='course_body p-2'>
                {/* <h5>{user_module_details?.title}</h5>
                <p>{user_module_details?.description}</p>
                <p><small>{user_module_details?.summary}</small></p> */}

                <div class="card shadow p-2 mb-3" >
                  <h5 class="card-title text-center text-success">{user_module_details?.title}</h5>
                  <div class="card-body">
                      <p class="card-text">{user_module_details?.description}</p>
                      <p class="card-text text-success" style={{ lineHeight: '1.2', letterSpacing: '1.2' }}><small>{user_module_details?.summary}</small></p>
                  </div>
              </div>

  
                <ul style={{ listStyle: "none" }}>
                  {user_module_details?.course_module_sections?.length >= 1 ? (
                    user_module_details?.course_module_sections.map((course_module, index) => (
                      <li key={index}>
                        <strong className='mb-5' style={{ textTransform: 'uppercase' }}>{course_module?.title}</strong>
                        <ContentWithLineBreaks content={course_module?.content} />
                      </li>
                    ))
                  ) : (
                    <Loader />
                  )}
                </ul>

                <div className='row p-2 d-flex justify-content-between'>
                    <div className='col-6'
                    //  onClick={() => setModule((prevModule) => prevModule - 1)}
                    onClick={handlePrevious}
                    >
                        <div className=' my-2'>
                            <img
                                src='../assets/images/carbon.png'
                                alt='Ujani logo'
                                style={{
                                    width: "1.2em",
                                }}>
                            </img>
                            <span className={`${module<=0 ? 'text-muted':''}`}>Previous</span>
                        </div>
                    </div>

                    <div className='col-6 d-flex justify-content-end'
                    //  onClick={() => setModule((prevModule) => prevModule + 1)}
                    onClick={handleNext}
                     >
                        <div className=' my-2'>
                            <span className={`${module == moduleIds.length-1 ?'text-muted':''}`}>Next</span>
                            <img
                                src='../assets/images/carbon2.png'
                                alt='Ujani logo'
                                style={{
                                    width: "1.2em",
                                }}>
                            </img>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>):(<Loader/>)}
    </div>
)
};

export default MyCourseModule;
