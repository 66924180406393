import users from './users.api'
import invoicing from './invoicing.api'
import packages from './packages.api'
import payments from './payments.api' 
import course from './courses.api'


export default {
    ...users,
    ...invoicing,
    ...packages,
    ...payments,
    ...course
}

