import React from 'react'
import MembershipModal from './MembershipModal';

const CommunityMemberships = () => {
    return (
        <div className='w-100 ' style={{backgroundColor:"#F1F3F4"}}>
            <div className='container '>
            <h2 className='text-center mb-5 pt-5' style={{color:"#016B3D"}}>
                <strong className=''>Ujani Community Memberships</strong>
                </h2>
            <MembershipModal/>

            <div className='row'>
                <div className='row' id="community_membership">
                    <div className='col-md-3'>
                        <div className='col-12 text-center'>
                            <img
                                src='./assets/images/frame_6.png'
                                alt='Ujani logo'
                                style={{
                                    width: "7em",

                                }}>
                            </img>
                            <h5 className='mt-3 text-center'><strong>Ujani Explorer Membership</strong></h5>
                            <p>This membership level <br/> is designed for <br/> individuals who are <br/> just starting their <br/> financial literacy <br/> journey and want to<br/> explore the basics of <br/> personal finance.</p>

                            <h6 style={{textDecoration:"none"}}>Free</h6>
                            <div className=''>
                                 <button className='btn mt-4 btn-outline-primary rounded-pill  px-4 py-1' 
                                 data-bs-toggle="modal" data-bs-target="#explorer">View</button>

                            </div>
                        </div>
                     
                    </div>

                    <div className='col-md-3'>
                        <div className='col-12 text-center'>
                            <img
                                src='./assets/images/frame_7.png'
                                alt='Ujani logo'
                                style={{
                                    width: "7em",

                                }}>
                            </img>
                            <h5 className='mt-3 text-center'><strong>Ujani Achiever Membership</strong></h5>
                            <p>The achiever <br/> membership offers a <br/> comprehensive range <br/> of resources and tools <br/> to help individuals <br/> achieve their financial <br/> goals and make <br/> informed decisions.</p>


                            <h6>Ksh. 15,000</h6>
                            <h4>*Ksh. 10,000 <span>Limited </span><span>offer</span></h4>
                            <div className=''>
                                 <button className='btn btn-outline-primary rounded-pill  px-4 py-1' 
                                 data-bs-toggle="modal" data-bs-target="#achiever">View</button>
                            </div>
                        
                        </div>
                   
                    </div>
                    <div className='col-md-3'>
                        <div className='col-12 text-center'>
                            <img
                                src='./assets/images/frame_8.png'
                                alt='Ujani logo'
                                style={{
                                    width: "7em",

                                }}>
                            </img>

                            <h5 className='mt-3 text-center'><strong>Ujani Expert Membership</strong></h5>
                            <p>This level provides <br/> advanced financial <br/> education and expert <br/> insights for individuals <br/> who want to deepen <br/> their knowledge and <br/> become financial <br/> experts themselves.</p>


                            <h6>Ksh. 25,000</h6>
                            <h4>*Ksh. 20,000 <span>Limited offer</span></h4>
                            <div className=''>
                                 <button className='btn btn-outline-primary rounded-pill  px-4 py-1' 
                                 data-bs-toggle="modal" data-bs-target="#expert">View</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='col-12 text-center'>
                            <img
                                src='./assets/images/frame_9.png'
                                alt='Ujani logo'
                                style={{
                                    width: "7em",

                                }}>
                            </img>
                            <h5 className='mt-3 text-center'><strong>Ujani Master Membership</strong></h5>
                            <p>This level offers a <br/> premium experience <br/> with exclusive access <br/> to advanced financial <br/> masterclasses, <br/> personalized <br/> coaching, and <br/> specialized resources</p>

                            <h6>Ksh. 75,000</h6>
                            <h4>*Ksh. 60,000 <span>Limited offer</span></h4>
                            <div className=''>
                                 <button className='btn btn-outline-primary rounded-pill  px-4 py-1' 
                                 data-bs-toggle="modal" data-bs-target="#master">View</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            </div>
        </div>
    )
}

export default CommunityMemberships;
