import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { packageCheckout, getPackageDetails } from '../../store/packages.store';
import { useState } from 'react';
import { courseCheckout } from '../../store/courses.store';
import {useSelector,useDispatch} from "react-redux";
import { ToastContainer } from "react-toastify";
import { getFromLocalStorage } from '../../utils/local-storage';


const CourseModal = (props) => {
    const course = props?.current_course
    const dispatch = useDispatch();


    const checkout = (id) => {
        dispatch(courseCheckout({ 'id': id })).then((result) => {
        })
        window.location.pathname = "/dashboard"
        dispatch({
            type: "active_route",
            payload: { "active_route": "transactions" }
        });
    }


    return (
        <div>
            <div class="modal membership_modal fade" id="course_modal" tabindex="-1" aria-labelledby="exampleCourseModal" aria-hidden="true">
            <div class="modal-dialog modal-lg w-100">

            {
            course ?  (
       
                    <div class="modal-content"> 
                        <div class="modal-body ml-auto ">
                            <button type="button" class="btn  btn-outline-light ml-auto" data-bs-dismiss="modal">
                            <img
                                    src='./assets/images/close.svg'
                                    alt='close'
                                    style={{width:"1em"}}></img>
                               
                            </button>
                        
                        </div>                   
                        <div class="modal-body row container p-1 mb-5">
                            <div className='col-md-6 p-5'>
                                <img
                                    src='./assets/images/products.png'
                                    alt='pentagon'
                                    className='card-img-top h-100 w-100'
                                />
                            </div>                  
                            <div className='col-md-6 pt-3'>
                                <h6><strong>{course?.title}</strong></h6>    
                                <p style={{lineHeight:"1em"}}><small className='text-dark'>{course?.description}</small></p>
                                <p className='card-text'><strong>Amount</strong> <strong style={{color:"rgba(37, 62, 144, 1)"}}>Ksh {course.amount}</strong></p>
                                
                                <p><button className='btn btn-success rounded-pill px-4 ' 
                                data-bs-dismiss="modal"   
                                onClick={() => checkout(course.id)}             
                                >Buy Course</button> 
                                </p>
                            </div>      
                        </div>
                    </div>
                ):(<div>Loading....</div>)}
                </div>

            </div>
        </div>
    )
}

export default CourseModal;
