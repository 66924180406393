import React, { useState, useEffect } from 'react'
import { useDispatch , useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import { invokeStk } from '../../store/payments.store';
import { Tabs, Tab } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { paymentInstructions } from '../../store/payments.store';
import { useNavigate } from 'react-router-dom';
import Loader from '../../utils/Loader';

const Pay = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const payData = location.state?.payData
  const instructions = useSelector((state)=>state?.payments?.instructions);
  const loading = useSelector((state)=>state?.payments?.loading);
  const phone_no = useSelector((state)=>state?.users.accountDetails.phone_number);
  const [msisdn, setMsisdn] = useState(phone_no);
  const navigate = useNavigate();

  console.log("The loading state is",  loading)
  useEffect(() => {
    dispatch(paymentInstructions(payData));
  }, []);

  const handleInputChange = (event) => {
    setMsisdn(event.target.value);
  };

  const handleCheckout = (msisdn) => {
    const payload = {
      "amount": parseInt(payData.amount),
      "bill_reference_number": payData.bill_reference_number.toString(),
      "action": "STK_PUSH",
      "recipient_phone": msisdn.toString()
    }

    dispatch(invokeStk(payload))
  }

  return (
    <div className='pay'>
      <p className='text-start py-2 ml-5'><div className=" mt-2" onClick={()=>navigate(-1)}>
      <img
                                src='./assets/images/carbon.png'  
                                alt='close'
                                style={{width:"1.5em"}}></img>
                                <strong className='ml-2'>Back</strong>
        </div></p>

      <h2 className='text-center mt-3'><strong>Payments</strong></h2>
      <div className='d-flex mt-3'>
        <nav aria-label="breadcrumb" className='text-center mx-auto'>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Dashboard</li>
            <li class="breadcrumb-item active">Pay</li>
          </ol>
        </nav>
      </div>

      <div className='tabs'>
        {instructions.length > 1 ?

        
        <Tabs defaultActiveKey="mpesa" className="tab">
          <Tab eventKey="mpesa" title="MPESA" className=''>
            <div className='instructions p-4 mx-1 mt-3'>
              <p><span className='start'>
                <img
                  src='../assets/images/mpesa.png'
                  alt='Ujani logo'
                  // className='card-img'
                  width='90em'
                /></span><strong className='end'>Lipa na Mpesa</strong></p>
              <p className='mb-0'><strong style={{ color: "#253E90" }}>Instructions</strong></p>

              <ol>
                <li>{instructions[1].payment_steps[0]}</li>
                <li>{instructions[1].payment_steps[1]}</li>
                <li>{instructions[1].payment_steps[2]}</li>
              </ol>

              <p><strong style={{ color: "#253E90" }}>Phone Number</strong></p>
              <div className='phone px-1 text-center rounded-pill'
                style={{
                  borderRadius: "5px solid red"
                }}>
                <img
                  src='../assets/images/kenya.png'
                  alt='Ujani logo'
                  className='mr-2'
                  width='35em'
                />
                <input type='number' placeholder='Enter your phone number'
                  value={msisdn}
                  onChange={handleInputChange}

                  style={{
                    border: "none"
                  }} className='' />
              </div>

              <button className='btn w-100 btn-success rounded-pill mt-5'
               disabled={loading}
                onClick={() => handleCheckout(msisdn)}>Complete</button>

          <div className='mx-auto text-center mt-2'>{loading ? <Loader/> : ""} </div>       



            </div>
          </Tab>

          <Tab eventKey="mpesa_express" title="MPESA EXPRESS" className=''>
            <div className='instructions p-4 mx-1 mt-3'>
              <p><span className='start'>
                <img
                  src='../assets/images/mpesa.png'
                  alt='Ujani logo'
                  // className='card-img'
                  width='90em'
                /></span><strong className='end'>Lipa na Mpesa</strong></p>
              <p className='mb-0'><strong style={{ color: "#253E90" }}>Instructions</strong></p>

              <ol>
                <li>{instructions[0].payment_steps[0]}</li>
                <li>{instructions[0].payment_steps[1]}</li>
                <li>{instructions[0].payment_steps[2]}</li>
                <li>{instructions[0].payment_steps[3]}</li>
                <li>{instructions[0].payment_steps[4]}</li>
                <li>{instructions[0].payment_steps[5]}</li>
                <li>{instructions[0].payment_steps[6]}</li>
                <li>{instructions[0].payment_steps[7]}</li>                
              </ol>

          


              {/* <button className='btn w-100 rounded-pill mt-5' onClick={() => handleCheckout(msisdn)}>Complete</button> */}


            </div>
          </Tab>
        </Tabs>
        
        
        : <p>Loading</p>}
      </div>

      {/* <div className='payment'>
        <div className='visa mx-1 mt-3 p-2'>
          <p className='px-0'><span className='start'>
            <img
              src='../assets/images/visa.png'
              alt='Ujani logo'
              className='mr-2'
              width='40em'
            />
            4855 ********</span>  <span className='end'>04/24</span></p>
        </div>

        <div className='visa mx-1 mt-3 p-2'>
          <p><span className='start'>
            <img
              src='../assets/images/orange.png'
              alt='Ujani logo'
              className='mr-2'
              width='40em'
            />
            4855 ******** </span><span className='end'>04/24</span></p>
        </div>

        <div className='visa mx-1 mt-3 p-2'>
          <h6 className='text-center'>
            <img
              src='../assets/images/CreditCard.png'
              alt='Ujani logo'
              className='mr-2'
              width='35em'
            />
            New Payment Cards</h6>
        </div>

        <div class="form-check m-2">
          <input type="checkbox" class="form-check-input" id="exampleCheck1"
            style={{
              backgroundColor: "green"
            }}
          />
          <p className='mx-2'><small>Remember this payment method</small></p>
        </div>

        <div className='px-2'>
          <button className='btn w-100 rounded-pill' onClick={() => handleCheckout(msisdn)}>Complete</button>
        </div>

      </div> */}


    </div>
  )
}

export default Pay;
