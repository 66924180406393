import './App.css';
import React, { useEffect, useCallback, Suspense } from "react";
import { BrowserRouter,Routes, Route, Link,useNavigate} from "react-router-dom";
import About from './components/pages/About';
import Navbar from './components/pages/Navbar';
import Home from './components/pages/Home';
import Team from './components/pages/Team';
import ProductServices from './components/pages/ProductServices';
import Footer from './components/pages/Footer';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Verify from './components/auth/Verify';
import Recover from './components/auth/Recover';
import Dashboard from './components/dashboard';
import MyCart from './components/dashboard/MyCart';
import Checkout from './components/dashboard/Checkout';
import Invoice from './components/dashboard/Invoice';
import TransactionHistory from './components/dashboard/TransactionHistory';
import TransactionDetails from './components/dashboard/TransactionDetails';
import Courses from './components/dashboard/Courses';
import Course from './components/dashboard/Course';
import MyCourse from './components/dashboard/MyCourse';
import Unit from './components/dashboard/Unit';
import ResetPassword from './components/auth/ResetPasword';
import PackageListing from './components/dashboard/PackageListing';
import Pay from './components/dashboard/Pay';
import { getFromLocalStorage } from './utils/local-storage';
import ProtectedRoute from './utils/ProtectedRoute';
import { useDispatch } from 'react-redux';
import { logout } from './store/users.store';
import MyCourseModule from './components/dashboard/MyCourseModule';


function App() {
  const Logout = () => {
    const dispatch = useDispatch();
    dispatch(logout())
    let navigate = useNavigate();
    const out = useCallback(() => {
      localStorage.clear();   
      navigate("/");
    }, [navigate]);

    useEffect(() => {
      out();
    }, [out]);
    return null;
  };

  const token = getFromLocalStorage('token');


  return (
      <div className="App w-100 h-100">   
      <BrowserRouter>
        <Suspense fallback={<p>Suspense  Loading ... </p>}>
          <Navbar/>
          <Routes>
            
            <Route path={"/about"} element={<About/>}></Route>
            {/* <Route path={token ? "/dashboard" : "/"} element={token ? <Dashboard /> : <Home />} /> */}
            <Route path={"/"} element={<Home />} />
            <Route path={"/team"} element={<Team/>}></Route>
            <Route path={"/auth"} element={<Login/>}></Route>
            <Route path={"/auth-register"} element={<Register/>}></Route>
            <Route path={"/auth-reset-password"} element={<ResetPassword/>}></Route>
            <Route path={"/auth-recover"} element={<Recover/>}></Route>
            <Route path={"/auth-verify"} element={<Verify/>}></Route>
            <Route path={"/dashboard"} element={<ProtectedRoute><Dashboard/></ProtectedRoute>}></Route>
            {/* <Route path={"/dashboard-my-cart"} element={<MyCart/>}></Route> */}
            <Route path={"/dashboard-package-listing"} element={<ProtectedRoute><PackageListing/></ProtectedRoute>}></Route>
            <Route path={"/dashboard-checkout/:id"} element={<ProtectedRoute><Checkout/></ProtectedRoute>}></Route>
            <Route path={"/dashboard-checkout"} element={<ProtectedRoute><Checkout/></ProtectedRoute>}></Route>
            <Route path={"/dashboard-pay"} element={<ProtectedRoute><Pay/></ProtectedRoute>}></Route>

            <Route path={"/dashboard-invoice/:id"} element={<ProtectedRoute><Invoice/></ProtectedRoute>}></Route>
            <Route path={"/dashboard-transaction-history"} element={<ProtectedRoute><TransactionHistory/></ProtectedRoute>}></Route>
            <Route path={"/dashboard-transaction-details"} element={<ProtectedRoute><TransactionDetails/></ProtectedRoute>}></Route>
            <Route path={"/dashboard-courses"} element={<ProtectedRoute><Courses/></ProtectedRoute>}></Route>
            <Route path="/courses/:id" element={<ProtectedRoute><Course/></ProtectedRoute>}></Route>
            <Route path="/my-courses/:id" element={<ProtectedRoute><MyCourse/></ProtectedRoute>}></Route>
            <Route path="/start-course" element={<ProtectedRoute><MyCourseModule/></ProtectedRoute>}></Route>


            {/* <Route path={"/dashboard/courses/course/unit"} element={<Unit/>}></Route> */}

            <Route path={"/product-services"} element={<ProductServices/>}></Route>
            <Route path={"/logout"} element={<Logout />} />
            
          </Routes>
          <Footer className=""/>
        </Suspense>
      </BrowserRouter>    
      </div>
  );
}

export default App;
