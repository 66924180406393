import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userCourse } from '../../store/courses.store';
import Loader from '../../utils/Loader';


const MyCourse = () => {
    const id = useParams();
    const course_details = useSelector((state) => state?.courses?.myCourse)
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(userCourse(id))
    }, [])

 
    return (
        <div>
        {course_details ? (<div>

            <p className='text-start py-2 ml-5'><div className=" mt-2" onClick={() => navigate(-1)}>
                <img
                    src='../assets/images/carbon.png'
                    alt='close'
                    style={{ width: "1.5em" }}></img>
                <strong className='ml-2'>Back</strong>
            </div></p>
            <img
                src='../assets/images/rectangle_9.png'
                alt='Ujani logo'
                style={{
                    width: "100%",
                    height: "30vh"
                }}>
            </img>
            <div className='course'>


                <div className='course_body p-2'>
                    <h3>{course_details.title}</h3>
                    {/* <p><span className='mr-2'>
                        <img
                            src='../assets/images/clock.png'
                            alt='Ujani logo'
                            // className='card-img'
                            width='15em'
                        />

                        1hr 45min</span>
                        <img
                            src='../assets/images/green_star.png'
                            alt='Ujani logo'
                            // className='card-img'
                            width='10em'
                        />
                        <img
                            src='../assets/images/green_star.png'
                            alt='Ujani logo'
                            // className='card-img'
                            width='10em'
                        />
                        <img
                            src='../assets/images/green_star.png'
                            alt='Ujani logo'
                            // className='card-img'
                            width='10em'
                        />
                        <img
                            src='../assets/images/green_star.png'
                            alt='Ujani logo'
                            className='mr-1'
                            width='10em'
                        />

                    </p> */}
                    {/* <h6>About this Course</h6>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas totam sunt sapiente

                    </p> */}

                    <h6>Course Modules </h6>
                    <ol >
                        {course_details?.course_modules?.length >=1 ?(
                        course_details?.course_modules?.map((course_module, index)=>(
                        <li key={index} ><strong>Module {index+1}:</strong> {course_module?.title}
                    
                                <p>{course_module?.description}</p>
                            
                        </li>))):(<Loader/>)
                        }

                    </ol>

                    <div className='px-5'>
                        <button className='btn btn-success mt-5 w-100 rounded-pill'><strong className='text-light'
                        onClick={()=>navigate("/start-course")}
                        >Start Course</strong></button>
                    </div>
                </div>
            </div>
        </div>):(<Loader/>)}
        </div>
    )
}

export default MyCourse;
