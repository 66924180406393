import React from 'react'
import Modal from './TeamModal';

const Team =()=> {
  return (
    <div>
        <Modal/>
        <div className='row team'></div>
        <div className='container team_section'>        
            <div className='row'>
                <h1 className='text col-12 text-center mt-5 mb-5' ><strong style={{color:"#016B3D"}}>Our Team</strong></h1>
                <div className='row mb-5'>
                    <div className='col-md-3 text-center'>
                            <img
                                src='./assets/images/don.png'
                                alt='pentagon'
                                style={{width:"15em",                                                    
                                }}>
                            </img>
                        <h4 className='mt-2'>FA DONALD KIMATHI</h4>
                        <h6 className=''>CO-FOUNDER  AND CEO</h6>
                        <p>Donald is an entrepreneur and a <br/>highly accomplished executive <br/>with 16 years’ experience<br/> spanning commercial & <br/>investment banking, capital <br/>markets, real estate, fintech, and<br/> e-commerce.</p>
                        <div className='col-12 text-center'>                           
                            <button 
                            type="button" class="btn btn-outline-primary rounded-pill  px-4 py-1" data-bs-toggle="modal" data-bs-target="#don">Bio</button>
                        </div>
                    </div>
                    <div className='col-md-3 text-center'>
                        <img
                                src='./assets/images/njeri.png'
                                alt='pentagon'
                                style={{width:"15em",                                                    
                                }}>
                            </img>
                        <h4 className='mt-2'>FA NJERI NDIRANGU</h4>
                        <h6 className=''>CO-FOUNDER AND CFO</h6>
                        <p>Njeri embarked on her <br/> professional journey as an <br/> investment advisor, and in 16 <br/> years, she’s left an indelible <br/> mark in the financial markets <br/> and investment advisory <br/> landscape. </p>
                        <div className='col-12 text-center'>
                            <button 
                            type="button" class="btn btn-outline-primary rounded-pill  px-4 py-1" data-bs-toggle="modal" data-bs-target="#njeri">Bio</button>
                        </div>
                    </div>
                    <div className='col-md-3 text-center'>
                             <img
                                src='./assets/images/nduta.png'
                                alt='pentagon'
                                style={{width:"15em",                                                    
                                }}>
                            </img>
                        <h4 className='mt-2'>PATROSE NDUTA</h4>
                        <h6 className=''>CO-FOUNDER AND COO</h6>
                        <p>
                        Patrose is a dedicated and <br/> seasoned C-Suite executive <br/> with a remarkable 16-year track <br/> record in the dynamic <br/> landscape of the Kenyan <br/> Financial Markets. 
                        </p>
                        <div className='col-12 text-center'>                        
                            <button 
                            type="button" class="btn btn-outline-primary rounded-pill  px-4 py-1 mt-3" data-bs-toggle="modal" data-bs-target="#nduta">Bio</button>
                        </div>
                    </div>
                    <div className='col-md-3 text-center'>
                            <img
                                src='./assets/images/grace.png'
                                alt='pentagon'
                                style={{width:"15em",                                                    
                                }}>
                            </img>
                        <h4 className='mt-2'>GRACE MURIUKI</h4>
                        <h6 className=''>PARTNERSHIPS LEAD</h6>
                        <p>Grace is a seasoned <br/> professional with over 10 years <br/> of experience in investments, <br/> insurance, and brokerage. She is <br/> is an IT engineering professional <br/> and a certified insurance agent.</p>
                        <div className='col-12 text-center'>                          
                            <button 
                            type="button" class="btn btn-outline-primary rounded-pill  px-4 py-1 mt-3" data-bs-toggle="modal" data-bs-target="#grace">Bio</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default Team;
