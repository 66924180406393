import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { getFromLocalStorage } from '../../utils/local-storage';
import { FaTimes } from 'react-icons/fa';


const Navbar = () => {
    const token = getFromLocalStorage("token")
    const location = useLocation();

    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        // Close the collapsed navbar when a link is clicked
        const navbarToggler = document.getElementById('navbarToggler');
        if (navbarToggler) {
            if (collapsed) {
                navbarToggler.click();
            }
        }
    }, [location.pathname]);


    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };


    return (
        <nav class={`navbar navbar-expand-lg w-100 ${window.location.pathname.includes("/auth") 
        || window.location.pathname.includes("/courses") 
        || window.location.pathname.includes("/my-courses") 
        || window.location.pathname.includes("/start-course") 
        || window.location.pathname.includes("/dashboard") ? 'd-none ' : ''} `} style={{ position: "relative", zIndex: "1000" }}>
            <div class="container-fluid">

                <Link class="navbar-brand" to="/">
                    <img
                        src='./assets/images/logo.png'
                        alt='Ujani logo'
                        style={{
                            width: "5em",
                            // zIndex:"1001"
                            marginLeft: "5em",
                            marginRight: "5em"
                        }}>
                    </img>
                </Link>

                {/* <button class="navbar-toggler collapsed"
                 id="navbarToggler"
                  type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button> */}






                {collapsed ? (
                    <button
                        className="navbar-toggler "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarTogglerDemo03"
                        aria-controls="navbarTogglerDemo03"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        id="navbarToggler"
                        onClick={toggleNavbar}
                    >
                        {/* <span className="navbar-toggler-icon"></span> */}
                        {/* <span className="close-icon">x</span> */}
                        <FaTimes size={15}/>
           


                    </button>
                ) : (
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarTogglerDemo03"
                        aria-controls="navbarTogglerDemo03"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        id="navbarToggler"
                        onClick={toggleNavbar}
                    >
                        <span className="navbar-toggler-icon"></span>
                        {/* <span className="close-icon">×</span> */}

                    </button>
                )}

                <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
                    <ul class="navbar-nav mb-2 mb-lg-0">
                        <li className={`nav-item ${location.pathname === '/' ? 'active ' : ''}`}>
                            <Link to="/" class="nav-link active text-success" aria-current="page" >Home</Link>
                        </li>
                        <li className={`nav-item ${location.pathname === '/about' ? 'active' : ''}`}>
                            <Link to="/about" class="nav-link text-success">Who we are</Link>
                        </li>
                        <li className={`nav-item ${location.pathname === '/product-services' ? 'active' : ''}`}>
                            <Link to="/product-services" class="nav-link text-success">Products & Services</Link>
                        </li>
                        <li className={`nav-item ${location.pathname === '/team' ? 'active' : ''}`}>
                            <Link to="/team" class="nav-link text-success">Our Team</Link>
                        </li>

                        {!token ?
                            (
                                <li className={`nav-item auth_btn mx-4 px-3 ${location.pathname === '/auth' ? 'active' : ''}`}>
                                    <Link to="/auth" class="nav-link btn auth_a"><span className='text-light'>Login/Sign Up</span></Link>
                                </li>
                            ) : (
                                <li className={`nav-item auth_btn mx-4 px-3 ${location.pathname === '/dasboard' ? 'active' : ''}`}>
                                    <a href="/dashboard" class="nav-link btn auth_a"><span className='text-light'>My Account</span></a>
                                </li>
                            )}



                        {/* <li className={`nav-item ${location.pathname === '/blog' ? 'active' : ''}`}>
                            <Link to="/blog" class="nav-link btn text-success">Blog</Link>
                        </li> */}

                        <li
                            class="form-control ml-5 mx-auto text-success d-flex" type="search" placeholder="Search" aria-label="Search"
                            style={{
                                borderRadius: "35px",
                                textAlign: "center",
                                width: "25vh",
                                fontFamily: "Poppins",
                            }}
                        ><div>
                                <img
                                    src='./assets/images/fa-search.svg'
                                    alt='Ujani logo'
                                    style={{
                                        width: "1.1em",
                                        marginRight: "2rem",
                                        marginLeft: "2rem"
                                    }}></img>
                            </div>Search

                        </li>

                    </ul>

                </div>
            </div>
        </nav>
    )
}

export default Navbar;
