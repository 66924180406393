/* eslint-disable class-methods-use-this */
import axios from 'axios';
import _ from 'lodash';
import {getFromLocalStorage} from "../utils/local-storage";


class Client {
    //The constructor, attributes definitions
    constructor(){
        //Setting the header variables
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        // axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE';
        axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';

        const meta = document.head.querySelector('meta[name="csrf-token"]');
        if (meta) {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = meta.content;
        }

        //Getting the user from the local storage
        // const user = getFromLocalStorage('user');
        const token = getFromLocalStorage('token') //Getting the user token
        // axios.defaults.headers.common.Authorization = `Bearer ${token}`; //specifying the authorization

        if (token) {
            // Token exists
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        } 
        
        // if(token){
        //     axios.defaults.headers.common.Authorization = ""; //specifying the authorization
        // }else if(token & window.location.pathname.includes("/dashboard")){
        //     axios.defaults.headers.common.Authorization = `Bearer ${token}`; //specifying the authorization
        // }


        //creating the base url
        this.http = axios.create({
            // baseURL : "https://test.quicksava.co.ke/api"  //test
            // baseURL : "https://4065-197-248-114-157.ngrok-free.app/"  //production
            baseURL : "https://portal.ujani.co.ke/api/v1"    //local
        });


        this.http.interceptors.response.use(response => response, async (error) => {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                // if (!window.location.href.includes("auth") && !window.location.href.includes("register")) {
                //     window.location.href = '/auth/login';
                // }

                window.location.href = '/auth';

            }
            return Promise.reject(error);
        });
    }

      
    //The functions
    get(path, params, config) {
        return this.http.get(path, {params, ...config});
    }

    post(path, data, config) {
        return this.http.post(path, data, config);
    }

    upload(path, data, file, config) {
        const form = new FormData();
        _.each(data, (value, key) => {
            form.append(key, value);
        });
        return this.post(path, form, {
            ...config,
            headers: {'Content-Type': 'multipart/form-data'},
        });
    }

    put(path, data, config) {
        return this.http.put(path, data, config);
    }

    delete(path, params, config) {
        return this.http.delete(path, {params, ...config});
    }

    parseResponse({data}) {
        return data;
    }
}

export default new Client();
