import React, { useEffect, useState } from 'react'
import MembershipModal from './MembershipModal';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CommunityMemberships from './CommunityMemberships';
import { useDispatch, useSelector } from 'react-redux';
import { courses, courseCheckout } from '../../store/courses.store';
import { useNavigate } from 'react-router-dom';
import CourseModal from './courseModal';


const Home = () => {
    const dispatch = useDispatch();
    const course_list = useSelector((state) => state?.courses?.courses);
    const navigate = useNavigate();
    const [course,setCourse] = useState({});

    useEffect(() => {
        dispatch(courses())
        console.log("Fetch courses called....");
    }, [])

    const checkout = (id) => {
        dispatch(courseCheckout({ 'id': id })).then((result) => {
        })
        window.location.pathname = "/dashboard"
        dispatch({
            type: "active_route",
            payload: { "active_route": "transactions" }
        });
    }

    return (
        <div className=''>
            <MembershipModal />
            <div className='hero'>
                <Carousel>
                    {/* <Carousel.Item>
                        <div className='row item-1'>
                            <div className='col-md-6 rectangle'>
                                <div className='hero_txt container justify-content-end'>
                                    <p className='text-start' id="home_txt">Empowering you to <br />make informed <br />financial decisions</p>
                                    <button className='btn btn btn-success rounded-pill px-4 py-3 mt-3' onClick={()=>navigate("/dashboard")}>Get Started</button>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item> */}
                    <Carousel.Item>
                        <div className='row item-5'>
                            <div className='col-md-6 rectangle'>
                                <div className='hero_txt container justify-content-end'>
                                    <p className='text-start' id="home_txt">Set your goals now <br />through the <strong>Budgeting <br />and Goal</strong>
                                        Setting Course<br />
                                        <span>Limited offer ksh.499</span>
                                    </p>

                                    <button className='btn btn btn-success rounded-pill px-4 py-3 mt-3' onClick={() => navigate("/dashboard")}>Get Started</button>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item>
                        <div className='row item-6'>
                            <div className='col-md-6 rectangle'>
                                <div className='hero_txt container justify-content-end'>
                                    <p className='text-start' id="home_txt"><strong>Debt Management,</strong> <br />
                                        A 30 minute course <br />to change your life <br />
                                        <span>Limited offer ksh. 999</span>
                                    </p>
                                    <button className='btn btn btn-success rounded-pill px-4 py-3 mt-3' onClick={() => navigate("/dashboard")}>Get Started</button>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item>
                        <div className='row item-2'>
                            <div className='col-md-6 rectangle'>
                                <div className='hero_txt container'>
                                    <p className='text-start' id="home_txt">Seize control of your <br />financial future with <br /> the <strong>Ujani personal <br /> Finance Masterclass</strong>
                                        <br />
                                        <span>Limited offer <strong>KSH. 4999</strong></span></p>
                                    <button className='btn btn btn-success rounded-pill px-4 py-3 mt-3' onClick={() => navigate("/dashboard")}>Get Started</button>

                                </div>
                            </div>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item>
                        <div className='row item-3'>
                            <div className='col-md-6 rectangle'>
                                <div className='hero_txt container'>
                                    <p className='text-start' id="home_txt">Get expert advice ... <br /><strong>the Ujani way <br />
                                    </strong>
                                        <span>Limited offer <strong>Ksh. 10,000</strong></span></p>
                                    <button className='btn btn btn-success rounded-pill px-4 py-3 mt-3' onClick={() => navigate("/dashboard")}>Get Started</button>

                                </div>
                            </div>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item>
                        <div className='row item-4'>
                            <div className='col-md-6 rectangle'>
                                <div className='hero_txt container'>
                                    <p className='text-start' id="home_txt">Join a community of <br /> investors and get <br /> support on your <br /><strong> journey to financial <br /> freedom</strong></p>
                                    <button className='btn btn btn-success rounded-pill px-4 py-3 mt-3' onClick={() => navigate("/dashboard")}>Get Started</button>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>

                <div className=''>
                    <div className='row'>
                        <div className='side_tree col-md-6  p-5 d-flex justify-content-end'>
                            <div className='side_tree_txt'>

                                <h1 className='text-success'>Who are we?</h1>
                                <p>Ujani is a financial literacy platform that is bridging the <br /> knowledge gap in personal
                                    finance and investment <br /> management among kenyans. We strive to empower <br />individuals to make informed financial decisions that <br /> ultimately lead to growth and prosperity </p>


                                <h1 className='text-success'>Money, Life , Abundance. </h1>
                                <p>Financial well-being extends beyond managing money. It <br />is the ability to lead a fulfilling life and experience the <br /> abundant opportunities that financial freedom can <br />provide </p>

                                <p><button className='btn btn-success rounded-pill mx-5 px-4 py-2 my-3' onClick={() => navigate("/dashboard")}>Get Started</button>
                                    {/* <button className='btn btn-outline-primary rounded-pill mx-5 px-4 py-2'>Learn More</button> */}
                                </p>

                            </div>
                        </div>
                        <div className='col-md-6 tree d-flex justify-content-end'>

                            <img
                                src='./assets/images/tree.png'
                                alt='Ujani logo'
                            ></img>
                        </div>

                    </div>

                </div>

                <div className='row p-5 green '>
                    <div className='col-12 text-center d-flex justify-content-center'>
                        <h3 className='text-light d-flex justify-content-center'>
                            <img
                                className='why_us_img'
                                src='./assets/images/Frame.png'
                                alt='pentagon'
                                style={{
                                    width: "1.9em",
                                    height: "1.9em"
                                }}
                            />
                            <span className='my-2'>We understand Personal Finance</span>
                        </h3>
                    </div>


                    <div className='col-12 text-center text-light'>
                        <p>We are dedicated to staying at the forefront of industry and global trends, <br />
                            ensuring that you receive the most up-to-date and insightful financial guidance <br />
                            from our team of certified financial advisors</p>
                    </div>

                    <div className='row justify-content-between container mx-auto text-light why_us'>
                        <div className='col-md-3'>
                            <div className='col-12'>
                                <h5 className='d-flex '>
                                    <img
                                        src='./assets/images/frame2.png'
                                        alt='pentagon'
                                        style={{
                                            width: "1.9em",
                                            height: "1.9em"
                                        }}>
                                    </img>
                                    <strong>Personalized <br />Learning</strong>
                                </h5>
                                <p>We understand that <br /> everyone's financial <br />situation is unique,
                                    <br /> Which is why we provide <br />Personalized solutions <br />tailored to your specific
                                    <br /> goals and challenges </p>
                            </div>
                            <div className='col-12 mt-5'>

                                <h5 className='d-flex '>
                                    <img
                                        src='./assets/images/frame3.png'
                                        alt='pentagon'
                                        style={{
                                            width: "1.9em",
                                            height: "1.9em"
                                        }}>
                                    </img>
                                    <strong>Value for <br />Money</strong>
                                </h5>
                                <p>Our services are <br /> designed to break down <br /> barriers and empower <br /> you, regardless of your
                                    <br /> background or financial <br /> status . We're here to <br /> make financial <br /> education inclusive and
                                    <br /> attainable for everyone</p>
                            </div>

                        </div>
                        <div className='col-md-6 p-5 text-center' id="pent">
                            <div className='mx-auto '>
                                <img
                                    src='./assets/images/pent.png'
                                    alt='pentagon'
                                >
                                </img>
                            </div>

                        </div>
                        <div className='col-md-3'>
                            <div className='col-12 text-end'>

                                <h5 className='d-flex'>
                                    <img
                                        src='./assets/images/frame1.png'
                                        alt='pentagon'
                                        style={{
                                            width: "1.9em",
                                            height: "1.9em",
                                            marginLeft: "7em"
                                        }}>
                                    </img>
                                    <strong>Positive <br />Impact</strong>
                                </h5>



                                <p>Our ultimate measure of <br /> success is the positive <br />financial changes our <br />clients experience. We <br />don't just
                                    offer <br />education; we are here <br /> to drive real and lasting <br />improvements in your <br /> financial well-being </p>
                            </div>
                            <div className='col-12 mt-5 text-end'>

                                <h5 className='d-flex'>
                                    <img
                                        src='./assets/images/frame4.png'
                                        alt='pentagon'
                                        style={{
                                            width: "1.9em",
                                            height: "1.9em",
                                            marginLeft: "6em"

                                        }}>
                                    </img>
                                    <strong>Innovation</strong>
                                </h5>
                                <p>We are commited to <br />innovation in financial <br /> education. We leverage <br /> cutting-edge
                                    <br /> technology and modern <br /> teaching methods<br /> to make financial learning <br /> engaging , interactive <br />
                                    and effective</p>
                            </div>
                        </div>


                    </div>

                </div>
                
                <div className='offered_courses mb-5'>
                    <h2 className='text-center my-5'><strong className='text-success'>Offered Courses</strong></h2>
                    <CourseModal current_course={course}/>

                    <div className='container'>

                    <div className='row d-flex flex-nowrap overflow-auto'>
                        {course_list.length >= 1 ? (
                            course_list.map((course, index) => (
                            <div className='col-lg-4 mb-5' key={index}>
                                <div className='card'>
                                <img
                                    src='./assets/images/products.png'
                                    alt='pentagon'
                                    className='card-img-top'
                                />
                                <div className='card-body'>
                                    <h6 className='card-title'>
                                    <strong className='text-dark'>{course.title}</strong>
                                    </h6>
                                    <p className='card-text'><strong>Amount</strong> <strong style={{color:"rgba(37, 62, 144, 1)"}}>Ksh {course.amount}</strong></p>
                                    <div className='d-flex justify-content-between '>
                                    <button className='btn btn-success rounded-pill px-4 py-2' onClick={() => checkout(course.id)}>
                                        Buy Course
                                    </button>
                                    <button className='btn btn-outline-primary rounded-pill px-4'
                                        data-bs-toggle="modal" key={index} data-bs-target="#course_modal"
                                        onClick={()=>setCourse(course)}
                                        >
                                        View Course
                                    </button>

                                    </div>
                                </div>
                                </div>
                            </div>
                            ))
                        ) : (
                            <div>Loading....</div>
                        )}
                        </div>
                    </div>

                </div>
                <CommunityMemberships />

                <div className='row rectangle_9'>
                    <div className='container'>
                        <p className='text-center'>At Ujani, we don’t just provide financial services; we provide a transformative financial <br /> journey. Join us and experience the difference as we guide you towards financial <br /> empowerment and a brighter financial future. <strong>Join us and get started today</strong></p>
                        <div className='text-center mt-5'>
                            <button className='btn btn-success text-center px-4 py-3' onClick={() => navigate("/dashboard")}>Get Started</button>

                        </div>

                    </div>

                </div>


            </div>
        </div>
    )
}

export default Home;
