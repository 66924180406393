import { Spinner } from 'react-bootstrap';

const Loader = () => {
    return (
        <div className='loader' style={{
            // position:"absolute",
            // left:"50vw"
        }}>
            <Spinner animation="grow"
                role="status"
                variant='success'
                style={{ width: '3rem', height: '3rem', left:"50vw" }}
            >
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default Loader;