import client from "./client";

export default {
    async login(credentials) {
        return client.parseResponse(await client.post('/auth/login', credentials))
    },

    async registerUser(data) {        
        return client.parseResponse(await client.post('/auth/signup', data))
    },

    async forgotPassword(data){
        return client.parseResponse( await client.post('/auth/forgot-password', data))
    },

    async resetPassword(data){
        return client.parseResponse( await client.post('/auth/reset-password', data))
    },

    async getAccountDetails(){
        return client.parseResponse(await client.get('/user-account/details'))
    },

}
