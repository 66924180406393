import React, { useDebugValue, useEffect } from 'react'
import { Link ,useNavigate} from 'react-router-dom';
import { userCourses, courseCheckout, courses} from '../../store/courses.store';
import { useDispatch,useSelector } from 'react-redux';
import Loader from '../../utils/Loader';

const MyCourses = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const active = props.active;
    const course_list = useSelector((state)=>state?.courses?.myCourses);
    const active_route = useSelector((state)=>state?.users?.active_route?.active_route)

    

    useEffect (()=>{
        // dispatch(userCourses())
        if(active_route == "my_courses"){
        dispatch(userCourses())
        }
    },[])


    const checkout = (id) =>{
        dispatch(courseCheckout({'id':id})).then((result)=>{
            console.log(result)
          if(result?.payload?.status === "PENDING"){
            navigate("/dashboard-transaction-history")
          }        
        })
    }
    return (
        <div className={`courses my_cart ${active_route == "my_courses" ? "" :"d-none"}`}>
            <h2 className='text-center mt-3 text-dark'><strong>My Courses</strong></h2>
            <div className='d-flex mt-3'>
                <nav aria-label="breadcrumb" className='text-center mx-auto'>
                    <ol class="breadcrumb">                    
                        <li class="breadcrumb-item"><Link to="/dashboard" className=''>Dashboard</Link></li>
                        <li class="breadcrumb-item active">My Courses</li>
                    </ol>
                </nav>
            </div>

 

            <div className='cart_items mt-4'>
                <ul className='mx-2'>
                    {course_list?.length >= 1  ?  course_list?.map((course)=>(
                    
                    <li key={course?.course?.id} className='cart_item  card shadow  mt-2'>
                    
                        <div className='row container'>
                            <div className='col-4'>
                                
                            </div>
                            <div className='col-8 text-start'>                            
                                <h6>{course.course?.title}</h6>
                                <p>{course?.course?.author?.full_name}</p>
                                {/* <p>{course?.description}</p> */}
                                {/* <p>{course.course}</p> */}
                                <p>                                
                                    <button className='btn btn-sm mx-2 '
                                    onClick={
                                        // ()=>navigate(`/courses/${course.id}`)
                                        ()=>window.location.pathname = `/my-courses/${course.course.id}`
                                    }
                                    >View</button>
                                </p>
                            </div>
                        </div>
                        
                    </li>
                    
                    )):((<Loader/>))}
                </ul>
            </div>
        </div>
    )
}

export default MyCourses;
