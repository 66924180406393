import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { packageCheckout } from '../../store/packages.store';
import { useState } from 'react';
import {useSelector,useDispatch} from "react-redux";
import { ToastContainer } from "react-toastify";
import { getFromLocalStorage } from '../../utils/local-storage';

const MembershipModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state)=>state.users.loading);
    const token = getFromLocalStorage('token');

    useEffect(()=>{
        // dispatch()
    },[])
    const checkout = (id) =>{
        if(token){
        dispatch(packageCheckout({'id':id})).then((result)=>{
            console.log(result)
          if(result?.payload?.status === "PENDING"){
            navigate("/dashboard-transaction-history")
          }        
        })
        // window.location.pathname = `/dashboard-checkout/${id}`
        // navigate(`/dashboard-checkout/${id}`)
        }else{
            alert("Please log in to subscribe")
            navigate("/auth")
        }

    }


    return (
        <div>
            {/* Explorer Modal */}
            <div class="modal membership_modal fade" id="explorer" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg w-100">
                    <div class="modal-content"> 
                        <div class="modal-body ml-auto">
                            <button type="button" class="btn  btn-outline-light ml-auto" data-bs-dismiss="modal">
                                <img
                                    src='./assets/images/close.svg'
                                    alt='close'
                                    style={{width:"1em"}}></img>
                            </button>
                        
                        </div>                   
                        <div class="modal-body row container mb-5">
                            <div className='col-md-4 '>
                                <img src='./assets/images/frame_6.png' alt='pentagon'></img>
                                <h4 className='text-center'>Ujani <br/> Explorer<br/> Membership</h4>
                            </div>                  
                            <div className='col-md-8'>
                                <p>Jambo and welcome to Ujani Explorer Community! <br/>
                                 At Ujani, we believe in the power of community to <br/> transform lives and drive financial success. </p>

                                <h4>Membership Benefits</h4>
                                <ul>
                                    <li>Free planning tools</li>
                                    <li>Free affiliate webinars</li>
                                    <li>Free e-books</li>
                                    <li>Ujani newsletters</li>
                                    <li>Access to Ujani Explorer Communities (WhatsApp groups)
                                    </li>
                                    <li>Exclusive offers on Ujani products</li>
                                </ul>

                                <p>
                                 <small>Terms and conditions apply</small></p>
                            </div>      
                        </div>
                    </div>
                </div>
            </div>

            {/* achiever modal */}
            <div class="modal membership_modal fade" id="achiever" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg w-100">
                <div class="modal-content"> 
                    <div class="modal-body ml-auto">
                        <button type="button" class="btn  btn-outline-light ml-auto" data-bs-dismiss="modal">
                            <img
                                src='./assets/images/close.svg'
                                alt='close'
                                style={{width:"1em"}}></img>
                        </button>
                      
                    </div>                   
                    <div class="modal-body row container mb-5">
                        <div className='col-md-4'>
                            <img src='./assets/images/frame_7.png' alt='pentagon'></img>
                            <h4 className='text-center'>Ujani<br/>Achiever<br/>Membership</h4>

                        </div>   
                        <div className='col-md-8'>
                                <p>
                                Jambo and welcome to Ujani Achiever Community! <br/>
                                Here, you'll discover exclusive insights, support, and <br/> resources tailored to your unique financial journey.
                                </p>

                                <h4>Membership Benefits</h4>
                                <ul>
                                    <li>Personal finance masterclass </li>
                                    <li>30% discount on Ujani events </li>
                                    <li>Free planning tools</li>
                                    <li>Free affiliate webinars</li>
                                    <li>Free e-books</li>
                                    <li>Ujani newsletters</li>
                                    <li>Access to Ujani Explorer Communities (WhatsApp groups)
                                    </li>
                                    <li>Exclusive offers on Ujani products</li>
                                </ul>

                                <p><button className='btn btn-success'
                                 data-bs-dismiss="modal" 
                                 onClick={()=>checkout("e9b4405f-464c-4a33-83cb-12c9d2d000c7")}
                                 >Subscribe</button> <small>Terms and conditions apply</small></p>
                            </div>                  
                          
                    </div>
                </div>
            </div>
            </div>

            {/* expert modal */}
            <div class="modal membership_modal fade" id="expert" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg w-100">
                <div class="modal-content"> 
                    <div class="modal-body ml-auto">
                        <button type="button" class="btn  btn-outline-light ml-auto" data-bs-dismiss="modal">
                            <img
                                src='./assets/images/close.svg'
                                alt='close'
                                style={{width:"1em"}}></img>
                        </button>
                      
                    </div>                   
                    <div class="modal-body row container mb-5">
                        <div className='col-md-4'>
                            <img src='./assets/images/frame_8.png' alt='pentagon'></img>
                            <h4 className='text-center'>Ujani<br/>Expert<br/>Membership</h4>

                        </div>  
                        <div className='col-md-8'>
                                <p>Jambo and welcome to Ujani Expert Community! <br/>
                                Here, you'll discover exclusive insights, support, and <br/> resources tailored to your unique finnacial journey.
                                </p>

                                <h4>Membership Benefits</h4>
                                <ul>
                                    <li>Investment masterclass</li>
                                    <li>Retirement planning</li>
                                    <li>Personal finance masterclass</li>
                                    <li>Financial markets recommendations</li>
                                    <li>30% discount on Ujani products</li>
                                    <li>Free planning tools</li>
                                    <li>Free affiliate webinars</li>
                                    <li>Free e-books</li>
                                    <li>Ujani newsletters</li>
                                    <li>Access to Ujani Explorer Communities (WhatsApp groups)
                                    </li>
                                    <li>Exclusive offers on Ujani products</li>
                                </ul>

                                <p><button className='btn btn-success' 
                                data-bs-dismiss="modal"
                                
                                onClick={()=>checkout("expert")}>Subscribe</button> <small>Terms and conditions apply</small></p>
                            </div>    
                                 
                           
                    </div>
                </div>
            </div>
            </div>

            {/* master modal */}
            <div class="modal membership_modal fade" id="master" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg w-100">
                <div class="modal-content"> 
                    <div class="modal-body ml-auto">
                        <button type="button" class="btn  btn-outline-light ml-auto" data-bs-dismiss="modal">
                            <img
                                src='./assets/images/close.svg'
                                alt='close'
                                style={{width:"1em"}}></img>
                        </button>
                      
                    </div>                   
                    <div class="modal-body row container mb-5">
                        <div className='col-md-4'>
                            <img src='./assets/images/frame_9.png' alt='pentagon'></img>
                            <h4 className='text-center'>Ujani <br/> Master <br/> Membership</h4>

                        </div>   
                        <div className='col-md-8'>
                                <p>Jambo and welcome to Ujani Master Community! <br/>
                                Here, you'll discover exclusive insights, support and<br/>
                                resources tailored to your unique financial journey. </p>

                                <h4>Membership Benefits</h4>
                                <ul>
                                    <li>Personal financial coach</li>
                                    <li>Annual financial plan</li>
                                    <li>Family office, wealth & estate planning</li>
                                    <li>Taxation planning</li>
                                    <li>Investement masterclass</li>
                                    <li>Retirement planning</li>
                                    <li>Personal finance masterclass</li>
                                    <li>30% discount on Ujani events</li>
                                    <li>Free planning tools</li>

                                    <li>Free affiliate webinars</li>
                                    <li>Free e-books</li>
                                    <li>Ujani newsletters</li>
                                    <li>Access to Ujani Explorer Communities (WhatsApp groups)
                                    </li>
                                    <li>Exclusive offers on Ujani products</li>
                                </ul>

                                <p><button className='btn btn-success' 
                                data-bs-dismiss="modal"
                                onClick={()=>checkout("master")}>Subscribe</button> <small>Terms and conditions apply</small></p>
                            </div>   
                    
                    </div>
                </div>
            </div>
            </div>




        </div>
    )
}

export default MembershipModal;
