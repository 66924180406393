import React from "react";
import {Navigate} from "react-router-dom";
import {getFromLocalStorage} from "./local-storage";

//if a person is not authenticated
const ProtectedRoute = ({children}) => {
    const token = getFromLocalStorage('token');
    return token ? children : <Navigate to="/auth"/>;
}

export default ProtectedRoute;
