import client from "./client";

export default {
    async getListPackages() {
        return client.parseResponse(await client.get('/packages'))
    },

    async getPackageDetails(data) {
        return client.parseResponse(await client.get('/packages/details',data))
    },

    async packageCheckout(data){
        return client.parseResponse(await client.post('/packages/checkout',data))
    },

    async packageCategories(){
        return client.parseResponse(await client.get('/packages/checkout'))
    }
}