import React from 'react'
import CommunityMemberships from './CommunityMemberships'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const ProductServices = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const handlePersonalFinancialEducationClicked = () => {
        //route to dashboard
        navigate("/dashboard")

        //set the active route to courses
        dispatch({
            type: "active_route",
            payload: {"active_route":"interests"}
        })
    }

    const handlecommunityMebershipsClicked = () => {
        //route to dashboard
        navigate("/dashboard")

        //set the active route to courses
        dispatch({
            type: "active_route",
            payload: {"active_route":"memberships"}
        })
    }

    const handleFinancialWellnessClicked =()=>{
        alert("Coming Soon ....")
    }


    return (
        <div>
            <div className='row products'></div>
            <div className='conatiner row'>
                <h1 className='col-12 text-center text-success mt-4' style={{ color: "#016B3D" }}>Our Products & Services</h1>
                <strong className='col-12 text-center'>A wide range of financial literacy & specialized training products tailored to our unique client needs</strong>
                <h5 className='col-12 text-center mt-5' ><strong style={{ color: "#253E90" }}>Ujani Personal Financial Education</strong></h5>
                <p className='col-12 text-center'>Engaging and practical personal finance education <br /> covering topics such as budgeting, saving, investing, and <br /> retirement planning.</p>
                <div className='col-12 text-center'>
                    {/* <button className='btn btn-outline-primary rounded-pill  px-4 py-1'>Learn More</button> */}
                </div>
            </div>




            <div className='container '>
                <div className='row justify-content-between container p-1 mx-auto heart'>
                    <div className='col-md-3'>
                        <div className='col-12 '>
                            <h5 className='d-flex'>
                                Ujani Personalized  <br />Financial Advisory
                            </h5>
                            <p>One-on-one <br /> consultations with our <br /> experienced financial <br /> advisors who provide <br /> customized financial <br /> plans and guidance.</p>
                            <button className='btn btn-outline-primary rounded-pill  px-4 py-1' onClick={() => handlePersonalFinancialEducationClicked()}>Learn More</button>
                        </div>
                        <div className='col-12 mt-5'>
                            <h5 className='d-flex'>
                                Ujani Master-classes <br /> and Specialized <br /> Training
                            </h5>
                            <p>Engaging and practical <br />personal finance <br /> education covering <br /> topics such as <br /> budgeting, saving, <br /> investing, and <br /> retirement planning.</p>
                            <button className='btn btn-outline-primary rounded-pill  px-4 py-1' onClick={() => handlePersonalFinancialEducationClicked()}>Learn More</button>
                        </div>

                    </div>
                    <div className='col-md-6  text-center' id="pent">
                        <div className=''>
                            <img
                                src='./assets/images/prodframe.png'
                                alt='pentagon'
                                style={{
                                    // width:"27em",                    
                                }}>
                            </img>
                        </div>

                    </div>
                    <div className='col-md-3'>
                        <div className='col-12'>

                            <h5 className='d-flex'>
                                Ujani Community<br /> Membership
                            </h5>
                            <p>Exclusive memberships <br /> to Ujani communities <br /> that provide individuals <br /> with a supportive <br /> network and additional <br /> resources to enhance <br /> their financial journey.</p>
                            <button className='btn btn-outline-primary rounded-pill  px-4 py-1' onClick={()=>handlecommunityMebershipsClicked()}>Learn More</button>
                        </div>
                        <div className='col-12 mt-5'>

                            <h5 className='d-flex'>

                                Ujani Corporate <br /> Financial <br /> Wellness Programs
                            </h5>
                            <p>Tailored financial <br /> wellness programs for <br /> businesses to improve <br /> the financial literacy <br /> and well-being of their <br /> employees.</p>
                            <button className='btn btn-outline-primary rounded-pill  px-4 py-1' onClick={()=>handleFinancialWellnessClicked()}>Learn More</button>

                        </div>
                    </div>


                </div>

            </div>


            <CommunityMemberships />




            <div className='container mt-5'>
                <div className='text-center'>
                    <h2 className='col-12 text-center mb-5' ><strong style={{ color: "#016B3D" }}>Our Clientelle</strong></h2>
                    <div className='row clientelle'>
                        <div className='col-md-3'>
                            <div>
                                <img
                                    src='./assets/images/retail.png'
                                    alt='Ujani logo'
                                    style={{
                                        width: "5em",

                                    }}>
                                </img>
                            </div>

                            <p>Retail <br />Clients</p></div>
                        <div className='col-md-3'>
                            <div>
                                <img
                                    src='./assets/images/family.png'
                                    alt='Ujani logo'
                                    style={{
                                        width: "5em"
                                    }}>
                                </img>
                            </div>
                            <p>Family <br />Offices</p></div>
                        <div className='col-md-3'>
                            <div>
                                <img
                                    src='./assets/images/non.png'
                                    alt='Ujani logo'
                                    style={{
                                        width: "5em"
                                    }}>
                                </img>
                            </div>
                            <p>Non profit <br />organization</p></div>
                        <div className='col-md-3'>
                            <div>
                                <img
                                    src='./assets/images/financial.png'
                                    alt='Ujani logo'
                                    style={{
                                        width: "5em"
                                    }}>
                                </img>
                            </div>
                            <p>Financial <br />Services</p></div>
                        <div className='col-md-3'>
                            <div>
                                <img
                                    src='./assets/images/govt.png'
                                    alt='Ujani logo'
                                    style={{
                                        width: "5em"
                                    }}>
                                </img>
                            </div>
                            <p>Government <br /> Entities</p></div>
                    </div>


                    <div className='row mt-5 mb-5 clientelle'>
                        <div className='col-md-3'>
                            <div>
                                <img
                                    src='./assets/images/listed.png'
                                    alt='Ujani logo'
                                    style={{
                                        width: "5em"
                                    }}>
                                </img>
                            </div>
                            <p>Listed <br />Companies</p></div>
                        <div className='col-md-3'>
                            <div>
                                <img
                                    src='./assets/images/corporate.png'
                                    alt='Ujani logo'
                                    style={{
                                        width: "5em"
                                    }}>
                                </img>
                            </div>
                            <p>Corporate <br />Entities</p></div>
                        <div className='col-md-3'>
                            <div>
                                <img
                                    src='./assets/images/religious.png'
                                    alt='Ujani logo'
                                    style={{
                                        width: "5em"
                                    }}>
                                </img>
                            </div>
                            <p>Religious <br />Institutions</p></div>
                        <div className='col-md-3'>
                            <div>
                                <img
                                    src='./assets/images/registered.png'
                                    alt='Ujani logo'
                                    style={{
                                        width: "5em"
                                    }}>
                                </img>
                            </div>
                            <p>Registered <br />Associations</p></div>
                        <div className='col-md-3'>
                            <div>
                                <img
                                    src='./assets/images/kenyans.png'
                                    alt='Ujani logo'
                                    style={{
                                        width: "5em"
                                    }}>
                                </img>
                            </div>
                            <p>Kenyans in <br />the diaspora</p></div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default ProductServices
