import client from "./client";

export default {
    async userInvoices(page) {
        return client.parseResponse(await client.get('/invoices/user-invoices?page='+page))
    },

    async invoiceDetails(id){   
        return client.parseResponse(await client.get('/invoices/details',id))
    }
}