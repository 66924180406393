import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../store/users.store';
import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer } from "react-toastify";
import Loader from '../../utils/Loader';
import { FaArrowCircleLeft} from 'react-icons/fa';


const Recover = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.users.loading);

    const handleFormSubmit = (values, { setSubmitting }) => {
        dispatch(forgotPassword(values)).then((result) => {
            if (result?.payload?.message === "success") {
                navigate("/auth-reset-password")
            }
        })
        setSubmitting(false)
    }
    return (
        <div className='auth'>
            <div className='container'>
                <ToastContainer/>
                <div className='row  login d-flex justify-content-center'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                        <div className='login_form mx-auto p-4 bg-light'>
                            <Formik
                            initialValues={{
                                email:""
                            }}
                            onSubmit={handleFormSubmit}
                            >
                            <Form>
                                 <FaArrowCircleLeft color='green' onClick={()=>navigate(-1)} size={20}/>     

                                <h4 className='text-center'><strong className=''>Trouble logging in?</strong></h4>
                                <p className='text-center'>Enter your email address and we will send <br />you an OTP to get back your account</p>
                                <div class="form-group">
                                    <label for="exampleInputEmail1"><strong>Email</strong></label>
                                    <Field type="email" name="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your Email Address" required/>
                                </div>

                                <div className='mx-auto text-center mt-2'>{loading ? <Loader /> : ""} </div>
                                <button type="submit" class="btn btn-success w-100">Recover Your Account</button>
                                <p className='text-center mt-3'>OR</p>
                            </Form>

                            </Formik>

                            <button type="submit" disabled={loading} class="btn btn-outline w-100" onClick={()=>navigate("/auth-register")}>Create New Account</button>


                        </div>
                    </div>
                    <div className='col-md-3'></div>
                </div>
            </div>
        </div>
    )
}

export default Recover;
