import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { signupUser } from '../../store/users.store';
import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer } from "react-toastify"
import Loader from '../../utils/Loader';
import { FaArrowCircleLeft} from 'react-icons/fa';


const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.users.loading);

  const handleFormSubmit = (values, { setSubmitting }) => {
    dispatch(signupUser(values)).then((result) => {
      if (result?.payload?.message === "success") {
        navigate("/auth")
      }
    })
    setSubmitting(false)
  }
  return (
    <div className='auth'>
      <ToastContainer/>
      <div className='container'>
        <div className='row  login d-flex justify-content-center'>
          <div className='col-md-3'></div>
          <div className='col-md-6'>
            <div className='register_form mx-auto px-4  bg-light'>
              <Formik
               initialValues={{
                email:"",
                first_name:"",
                surname:"",
                password:"",
                password_confirm:"",
                phone_number:"",
                referall_code:""
              }}

              onSubmit={handleFormSubmit}              
              
              >
              <Form className='pt-3'>
              <FaArrowCircleLeft color='green' onClick={()=>navigate(-1)} size={20}/> 

                <h4 className='text-center'><strong className=''>Create An Account</strong></h4>
                <p className='text-center'>Seize control of your financial future today</p>
                <div className='row'>
                  <div class="form-group col-6">
                    <label for="exampleInputEmail1"><strong>First Name</strong></label>
                    <Field type="text" name="first_name" class="form-control" id="first_name" aria-describedby="emailHelp" placeholder="Enter your first name" required />
                  </div>
                  <div class="form-group col-6">
                    <label for="exampleInputEmail1"><strong>Last Name</strong></label>
                    <Field type="text" name="surname" class="form-control" id="last_name" aria-describedby="emailHelp" placeholder="Enter your last name" required/>
                  </div>
                </div>

                <div className='row'>
                  <div class="form-group col-6">
                    <label for="exampleInputEmail1"><strong>Email</strong></label>
                    <Field type="email" name="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter your email" required/>
                  </div>
                  <div class="form-group col-6">
                    <label for="exampleInputEmail1"><strong>Phone Number</strong></label>
                    <Field type="number" class="form-control"name="phone_number" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your Number" required/>
                  </div>
                </div>


                <div class="form-group">
                  <label for="exampleInputEmail1"><strong>Password</strong></label>
                  <Field type="password" name="password" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your Password" required/>
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1"><strong>Confirm Password</strong></label>
                  <Field type="password" name="password_confirm" class="form-control" id="exampleInputPassword1" placeholder="Confirm Your Password" required />
                </div>
                <small>By creating an account, you agree to the Ujani <Link>Terms and conditions</Link> and <Link>Privacy Policy</Link></small>

                <div className='mx-auto text-center mt-2'>{loading ? <Loader /> : ""} </div>
                <button type="submit" disabled={loading} class="my-3 btn btn-success w-100">Create Account</button>
                <p className='text-center  mb-2'><span className='text-dark'>Already have an account?</span><Link to="/auth">Log in</Link></p>
              </Form>
              </Formik>

            </div>
          </div>
          <div className='col-md-3'></div>
        </div>
      </div>
    </div>
  )
}

export default Register;
