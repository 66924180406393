import client from "./client";
import payment_client from "./payment_client ";


export default {
    async ReceivePayments(credentials) {
        return client.parseResponse(await payment_client.post('/transactions/receive-payment', credentials))
    },

    async getPaymentInstruction(credentials){
        return client.parseResponse(await payment_client.post('/order/create', credentials))
    },

    async invokeStk(credentials){
        return client.parseResponse(await payment_client.post('/order/invoke-action',credentials))
    }
}