import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { invokeStk } from '../../store/payments.store';
import { useNavigate } from 'react-router-dom';
import Loader from '../../utils/Loader';



const PayAccordion = (props) => {
    const dispatch = useDispatch();
    const payData = props.paydata
    const instructions = useSelector((state) => state?.payments?.instructions);
    const loading = useSelector((state) => state?.payments?.loading);
    const phone_no = useSelector((state) => state?.users?.accountDetails?.phone_number);
    const [msisdn, setMsisdn] = useState(phone_no);
    const navigate = useNavigate();
    const active = props.active

    const handleInputChange = (event) => {
        setMsisdn(event.target.value);
    };

    const handleCheckout = (msisdn) => {
        const payload = {
            "amount": parseInt(payData?.amount),
            "bill_reference_number": payData?.bill_reference_number.toString(),
            "action": "STK_PUSH",
            "recipient_phone": msisdn.toString()
        }

        console.log("The stk payload is ", payload)

        dispatch(invokeStk(payload))
    }


    return (
        <div className="accordion mx-1" id="accordionExample">
            <div className="accordion-item w-100">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button  " style={{outline:"none"}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <div className=''>
                            <h6 className='text-success'>Mpesa Express </h6>
                        </div>
                    </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <div className='instructions'>
                            <p><span className='start'>
                                <img
                                    src='../assets/images/mpesa.png'
                                    alt='Ujani logo'
                                    // className='card-img'
                                    width='90em'
                                /></span><strong className='end'>Lipa na Mpesa</strong></p>
                            <p className='mb-0'><strong style={{ color: "#253E90" }}>Instructions</strong></p>

                            <ol>
                                <li>{instructions[1]?.payment_steps[0]}</li>
                                <li>{instructions[1]?.payment_steps[1]}</li>
                                <li>{instructions[1]?.payment_steps[2]}</li>
                            </ol>

                            <p><strong style={{ color: "#253E90" }}>Phone Number</strong></p>
                            <div className='phone px-1 text-center rounded-pill'
                                style={{
                                    borderRadius: "5px solid red"
                                }}>
                                <input type='number' placeholder='Enter your phone number'
                                    value={msisdn}
                                    className="p-2 mx-1 text-center"
                                    onChange={handleInputChange}
                                    style={{
                                        border: "none"
                                    }}  />
                            </div>
                            <div className='mx-auto text-center mt-2'>{loading ? <Loader /> : ""} </div>
                            
                            <button className='btn w-100 btn-success rounded-pill '
                                disabled={loading}
                                onClick={() => handleCheckout(msisdn)}>Complete</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <div>
                            <h6 className='text-success'>Mpesa</h6>
                        </div>
                    </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <div className='instructions p-1 mx-1 mt-3'>
                            <p><span className='start'>
                                <img
                                    src='../assets/images/mpesa.png'
                                    alt='Ujani logo'
                                    // className='card-img'
                                    width='90em'
                                /></span><strong className='end'>Lipa na Mpesa</strong></p>
                            <p className='mb-0'><strong style={{ color: "#253E90" }}>Instructions</strong></p>

                            <ol>
                                <li>{instructions[0]?.payment_steps[0]}</li>
                                <li>{instructions[0]?.payment_steps[1]}</li>
                                <li>{instructions[0]?.payment_steps[2]}</li>
                                <li>{instructions[0]?.payment_steps[3]}</li>
                                <li>{instructions[0]?.payment_steps[4]}</li>
                                <li>{instructions[0]?.payment_steps[5]}</li>
                                <li>{instructions[0]?.payment_steps[6]}</li>
                                <li>{instructions[0]?.payment_steps[7]}</li>
                            </ol>

                            <button className='btn w-100 btn-success rounded-pill'
                                disabled={loading}
                    >Complete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayAccordion;
