import React from 'react'

const About = () => {
    return (
        <div className=''>
            <div className='about'>
                <div className='container'>
                    <div className='row text-light col-12 w-100 d-flex text-center'>
                        <div className='col-md-6'>
                            <img src='./assets/images/mission.png' lt='Ujani logo'>

                            </img>
                            <h2 className='mt-3'><strong className='text-light'>Mission</strong></h2>
                            <p>“Empower Kenyans to make <br/> informed financial decisions <br/> and achieve lifelong well-<br />being.</p>
                        </div>
                        <div className='col-md-6'>
                            <img src='./assets/images/mission.png' alt='Ujani logo'>
                            </img>
                            <h2 className='mt-3'><strong className='text-light'>Vision</strong></h2>
                            <p>“To be the go-to provider of<br /> financial literacy services in<br/> Kenya, creating a better<br /> future for all.”</p>
                        </div>

                    </div>
                </div>
            </div>

            <div className='row fin_lit'>
                <h2 className="text-center col-12  mt-5 mb-0">Why Is Personal Financial Literacy Important?</h2>
                <strong className="text-center col-12">Imagine having the power to control your destiny</strong>


                <ol className="px-5 py-3">
                    <p className="col-12">Ujani empowers you to make smart money decisions that benefit you in the following ways:</p>


                    <li><strong>1. Stress-Free Living </strong> <br />
                        Say goodbye to financial stress! Know how to budget, save, and spend wisely,<br/>
                         giving you peace of mind.</li>
                    <li><strong>2. Your Money Works for You </strong><br/>
                         Learn how to invest and grow your savings. We’re here to guide you as you grow your wealth.</li>
                    <li><strong>3. Secure future </strong> <br/>
                        Plan for a comfortable retirement without worries. Secure your golden years.
                    </li>

                    <li>
                        <strong>4. Smart Debt Management </strong> <br />
                        Master the art of managing and reducing debt. Improve your credit score and save on interest.
                    </li>

                    <li>
                        <strong>5. Financial Freedom </strong> <br />
                        Gain independence to achieve your goals and dreams.
                    </li>
                    <p className="col-12 mb-5"><strong><em className="">
                        With Ujani, you’re in control. Say hello to a life with less stress and more opportunities!</em></strong></p>
                </ol>

            </div>

            <div className='row'>
                <div className='col-md-6 text-light ujani_way'>
                    <div className="txt">
                        <h3>The Ujani Way</h3>
                        <p>The “Ujani way” is a unique <span className="" style={{ color: "#9CB74E" }}>3-step <br/> methodology </span>that covers all<br/> aspects of financial literacy , <br /> connecting money knowledge with <br /> personal priorities.</p>
                        <p>
                            The Ujani way is a practical, tailor-<br />made approach that promotes <br />lifelong learning and growth.</p>
                    </div>

                </div>

                <div className='col-md-6 px-5  accordion'>
                    <div className='accordion-item'>
                        <h3 className="accordion-button bg-light" 
                        // style={{ color: "#253E90" }}
                            data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                        >

                            <img src='./assets/images/ph.png' alt='Ujani logo' style={{ width: "1.5em" }}></img>
                            Unlocking financial awareness</h3>

                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div className='accordion-body'>
                                <p><strong>Diagnose: </strong><br />
                                     Understanding your current financial situation,<br/> goals, and challenges.</p>
                                <p><strong>
                                        Educate: </strong><br />
                                      Build a strong foundation of financial literacy.</p>
                            </div>
                        </div>


                    </div>



                    <div className='accordion-item mt-3'>
                        <h3 className="accordion-button collapsed bg-light"
                            data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="" aria-controls="collapseTwo"
                        >

                            <img
                                src='./assets/images/ph_q.png'
                                alt='Ujani logo'
                                style={{
                                    width: "1.8em",
                                }}></img>Building Wealth, One Step at a Time

                        </h3>

                        <div id="collapseTwo" class="accordion-collapse collapse " data-bs-parent="#accordionExample2">
                            <div className='accordion-body'>
                            <p><strong>Strategize: </strong><br />
                             Develop a diversified investment plan aligned to financial road map, goals
                            and risk tolerance.</p>
                            
                         
                            <p><strong>
                            Execute: </strong>
                            Take action to implement your investment plan, whether it’s in money
                            markets, stocks, Saccos, bonds, real estate, or other opportunities.</p>
                            </div>
                        </div>
                    </div>



                    <div className='accordion-item mt-3 '>
                        <h3 className="accordion-button collapsed bg-light" 
                            data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="" aria-controls="collapseThree"
                        >

                            <img
                                src='./assets/images/ph_qu.png'
                                alt='Ujani logo'
                                style={{
                                    width: "1.8em",
                                }}></img>Achieving Financial Independence

                        </h3>

                        <div id="collapseThree" class="accordion-collapse collapse " data-bs-parent="#accordionExample3">
                            <div className='accordion-body'>
                            <p><strong>Empower: </strong><br />                                   
                                        Equip yourself with advanced knowledge, tools &amp; opportunities to secure
                                    your financial future.
                                    </p>
                                    <p>
                                    <strong>
                                        Celebrate: </strong><br />
                                            Celebrate your milestones and successes along the way. Financial
                                        freedom is a journey worth cherishing.
                                    </p>

                            </div>
                        </div>
                    </div>



                </div>


            </div>


            <div className='container mt-5 delivery_methods'>
                <h2 className='text-center mb-5'><strong>Training Delivery Methods</strong></h2>

                <div className='mb-5'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <img
                                    src='./assets/images/group2.png'
                                    alt='Ujani logo'
                                    style={{ width: "4em" }}>
                                </img>
                                <h4 className='mt-2'><strong>Workshops</strong></h4>

                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className='text-center'>
                                <img
                                    src='./assets/images/group6.png'
                                    alt='Ujani logo'
                                    style={{ width: "4em" }}>
                                </img>
                                <h4 className='mt-2'><strong>Online <br /> Webinars</strong></h4>

                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <img
                                    src='./assets/images/group3.png'
                                    alt='Ujani logo'
                                    style={{ width: "4em" }}>
                                </img>
                                <h4 className='mt-2'><strong>USSD Quick <br />Learning</strong></h4>

                            </div>
                        </div>
                        <div className='col-md-3    '>
                            <div className='text-center'>
                                <img
                                    src='./assets/images/group4.png'
                                    alt='Ujani logo'
                                    style={{ width: "4em" }}>
                                </img>
                                <h4 className='mt-2'><strong>E-Learning</strong></h4>

                            </div>
                        </div>

                    </div>

                </div>
            </div>


        </div>
    )
}

export default About;
