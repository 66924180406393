import React ,{useEffect, useState}from 'react'
import { Link ,useNavigate} from 'react-router-dom';
import { getListPackages ,getPackageDetails} from '../../store/packages.store';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import PackageModal from '../pages/PackageModal';
import Loader from '../../utils/Loader';

const PackageListing = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const packages = useSelector((state)=>state?.packages?.packages?.results);
    const package_details = useSelector((state)=>state?.packages?.packageDetail);
    const active_route = useSelector((state)=>state?.users?.active_route?.active_route)



    useEffect(() => {
        if(active_route == "memberships"){
         dispatch(getListPackages())
        }
    }, []);

    const handlePackageClicked =(id)=>{
        console.log("The id received is", {id})
        dispatch(getPackageDetails({'id':id}))
    }

    return (
        <div className={`transaction_history ${active_route == "memberships" ? "" :"d-none"}`}>
            <h2 className='text-center mt-3'><strong>Package Listings</strong></h2>
            <div className='d-flex mt-3'>
                <nav aria-label="breadcrumb" className='text-center mx-auto'>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li class="breadcrumb-item active"><Link to="/dashboard">Packages</Link></li>
                    </ol>
                </nav>
            </div>
            <PackageModal />
            <div class="table-responsive mt-5">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Package</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {packages ? packages?.map((packag_e,index)=>(
                            <tr className='' data-bs-toggle="modal" key={index} data-bs-target="#explorer"
                            onClick={()=>handlePackageClicked(packag_e.id)}
                            >                            
                            <td>{packag_e.name}</td>
                            <td><small className='pending rounded-pill px-3 py-1'>{packag_e.amount}</small></td>                            
                        </tr>)):(<Loader/>)
                        
                        }         
                    </tbody>
                </table>
            </div>

            <div className='px-5'>
                <button className='btn btn-success mt-5 w-100 rounded-pill'>Dashboard</button>
            </div>

        </div>
    )
}

export default PackageListing;
