import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { userInvoices } from '../../store/invoicing.store';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import InvoiceModal from './InvoiceModal';
import { FaEye, FaTimes } from 'react-icons/fa';
import PayAccordion from './payAccordion';
import { paymentInstructions } from '../../store/payments.store';
import Loader from '../../utils/Loader';




const TransactionHistory = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user_invoices = useSelector((state) => state?.invoices?.userInvoices?.results);
    const previous = useSelector((state) => state?.invoices?.userInvoices?.previous);
    const next = useSelector((state) => state?.invoices?.userInvoices?.next);

    const active_route = useSelector((state) => state?.users?.active_route?.active_route)
    const [activeRow, setActiveRow] = useState(null);
    const [pay, setPay] = useState(false)
    const [payData, setPayData] = useState({});

    const [page, setPage] = useState(1)


    useEffect(() => {
        if (active_route == "transactions") {
            dispatch(userInvoices(page))
        }
    }, [page]);

    function handlePayClicked(amount, reference_number) {
        setActiveRow(reference_number)
        setPay(!pay)
        const pay_data = {
            "amount": amount,
            "bill_reference_number": reference_number,
            "payment_notes": ""
        };

        setPayData(pay_data)

        //dispatch call to fetch instrcutions here, only call it when the pay state is true
        if(!pay){
            dispatch(paymentInstructions(pay_data));
        }


        // navigate('/dashboard-pay', { state: { payData } });
    }

    const handleViewClicked = (id) => {
        // navigate(`/dashboard-invoice/${id}`)

        //dispatch call to set the invoice detail as the active route
        dispatch({
            type: "active_route",
            payload: { "active_route": "invoice" }
        });

        //dispatch another call to set the active invoice id in the invoice store
        dispatch({
            type: "invoice_id",
            payload: { "invoice_id": id }
        })

    }



    return (
        <div className={`transaction_history ${active_route == "transactions" || window.location.pathname == "/dashboard-transaction-history" ? "" : "d-none"}`}>
            <h2 className='text-center mt-3'><strong>Transaction History</strong></h2>
            <div className='d-flex mt-3'>
                <nav aria-label="breadcrumb" className='text-center mx-auto'>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li class="breadcrumb-item active"><Link to="/dashboard">Invoices</Link></li>
                    </ol>
                </nav>
            </div>

            <div class="table-responsive mt-5">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {user_invoices?.length >= 1 ? user_invoices?.map((invoice, index) => (
                            <>
                                <tr key={index} className=''>
                                    <td>{invoice.reference_number} </td>

                                    <td>{invoice.amount}   <FaEye color='green' className='ml-2'
                                        onClick={() => handleViewClicked(invoice.id)}
                                    />  </td>
                                    <td><small className='pending rounded-pill px-2 py-1'>{invoice.status}</small></td>
                                    {invoice.status == "PENDING" ? (
                                        <>
                                            <td><button className={`${pay && activeRow == invoice.reference_number ? "btn-danger" : "btn-success"} w-100 btn  btn-sm px-4`}
                                                onClick={() => handlePayClicked(invoice.amount, invoice.reference_number)}
                                            >{pay && activeRow == invoice.reference_number ? " X " : "Pay"}</button></td>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </tr>
                                <tr>
                                    <td colSpan="4" className={` ${activeRow == invoice.reference_number && pay ? '' : 'd-none'}`}>
                                        <PayAccordion active={activeRow == invoice.reference_number} paydata={payData} />
                                    </td>
                                </tr>
                            </>
                        ))

                            : (<Loader/>)}
                    </tbody>
                </table>
            </div>

            <div className="d-flex justify-content-center mt-3">
                <nav aria-label="Page navigation">
                    <ul className="pagination">
                        <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                            <button
                                disabled={previous === null ? true : false}
                                className="page-link"
                                onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
                            >
                                Previous
                            </button>
                        </li>
                        {/* You can customize the number of pages based on your backend response */}
                        <li className="page-item">
                            <span className="page-link">{page}</span>
                        </li>
                        <li className="page-item">
                            <button
                                disabled={next === null ? true : false}
                                className="page-link"
                                onClick={() => setPage((prevPage) => prevPage + 1)}
                            >
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default TransactionHistory;
