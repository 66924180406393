import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { invoiceDetails } from '../../store/invoicing.store';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../utils/Loader';

const Invoice = (props) => {
  const id = useParams()
  const dispatch = useDispatch()
  const active = props;
  const loading = useSelector((state) => state.users.loading);
  const invoice_details = useSelector((state) => state?.invoices?.invoiceDetails);
  const active_route = useSelector((state) => state?.users?.active_route?.active_route)
  const invoice_id = useSelector((state)=> state?.invoices?.invoice_id?.invoice_id) 

  const navigate = useNavigate()



  useEffect(() => {
    if(active_route == "invoice"){
      dispatch(invoiceDetails({id:invoice_id}))
    }
  }, [invoice_id]);

  return (
    <div className={`invoice  ${active_route == "invoice" ? "" : "d-none"}`}>
      {/* <p className='text-start py-2 ml-5'><div className=" mt-2" onClick={() => navigate(-1)}>
        <img
          src='../assets/images/carbon.png'
          alt='close'
          style={{ width: "1.5em" }}></img>
        <strong className='ml-2'>Back</strong>
      </div></p> */}

      <h1 className='text-center mt-3 text-dark'>Membership Invoice</h1>
      <div className='d-flex mt-3'>
        <nav aria-label="breadcrumb" className='text-center mx-auto'>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
            <li class="breadcrumb-item active">Invoice</li>

          </ol>
        </nav>
      </div>


      {invoice_details ? (
        <>
       <div className='invoice_hero p-1 t'>

        <div className='row container'>
          <div className='col-12 mb-3'>
            <label className='px-1 mt-5'>Invoice number : </label>
            <p>No : {invoice_details.reference_number}</p>
          </div>
          <div className='col-12'>
            <label className='px-1'>Invoice to:</label>
            <h6>{invoice_details?.customer?.full_name}</h6>
            <p>{invoice_details?.customer?.phone_number}</p>
            <p>{invoice_details?.customer?.email}</p>
          </div>

          <div className='col-6'>
            <label className='px-1'>Amount:</label>
            <h6>{invoice_details?.amount}</h6>
          </div>
          <div className='col-6'>
            <label className='px-1'>Status:</label>
            <h6>{invoice_details?.status}</h6>
          </div>
        </div>
      </div>


      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Item</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {
              console.log("invoice items", invoice_details?.invoice_items)
            }
            {invoice_details.invoice_items && invoice_details?.invoice_items?.map((invoice_item, index) => (
              <tr key={index}>
                <td><small className="text-muted ">{invoice_item?.narration}</small></td>
                <td>{invoice_item?.amount}</td>
              </tr>
            ))
            }
          </tbody>
        </table>
      </div>
      </>
      ):(<Loader/>)}

      <div className='p-2'>
        {/* <button className='btn btn-success w-100 rounded-pill'><strong>Save As PDF</strong></button> */}
        <button className='btn btn-outline-primary mt-3 w-100 rounded-pill' onClick={() => navigate("/dashboard")}><strong>Dashboard</strong></button>
      </div>
    </div>
  )
}

export default Invoice;
