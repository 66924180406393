import React from 'react'

const TeamModal = () => {
    return (
        <div>
            {/* Don Modal */}
            <div class="modal team_modal fade" id="don" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg w-100">
                    <div class="modal-content"> 
                        <div class="modal-body ml-auto">
                            <button type="button" class="btn  btn-outline-light ml-auto" data-bs-dismiss="modal">
                                <img
                                    src='./assets/images/close.svg'
                                    alt='close'
                                    style={{width:"1em"}}></img>
                            </button>
                        
                        </div>                   
                        <div class="modal-body row container mb-5">
                            <div className='col-md-4'>
                                <img src='./assets/images/don2.png' alt='pentagon'></img>
                            </div>                  
                            <div className='col-md-8'>
                                <h4 className=''>FA DONALD KIMATHI</h4>
                                <h6 className=''>CO-FOUNDER AND CEO</h6>
                                <p style={{lineHeight:"1.2em"}}>Donald is an entrepreneur and a highly accomplished <br/> executive with 16 years’ experience spanning commercial <br/> & investment banking, capital markets, real estate, fintech, <br/> and e-commerce. He has expertise in strategy, growth <br/> marketing, distribution channel management, coaching, <br/> product development, partnerships & alliances, <br/> investments and corporate training.</p>

                                <p style={{lineHeight:"1.2em"}} className='mt-2'>Donald is an Economics graduate from Moi University and <br/> a member of various professional bodies including ICIFA, <br/> CISI & ESK. He serves in various advisory roles and boards <br/> in the financial services industry in East Africa.</p>
                            </div>      
                        </div>
                    </div>
                </div>
            </div>

            {/* Njeri modal */}
            <div class="modal team_modal fade" id="njeri" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg w-100">
                <div class="modal-content"> 
                    <div class="modal-body ml-auto">
                        <button type="button" class="btn  btn-outline-light ml-auto" data-bs-dismiss="modal">
                            <img
                                src='./assets/images/close.svg'
                                alt='close'
                                style={{width:"1em"}}></img>
                        </button>
                      
                    </div>                   
                    <div class="modal-body row container mb-5">
                        <div className='col-md-4'>
                            <img src='./assets/images/njeri2.png' alt='pentagon'>
                            </img>
                        </div>                  
                        <div className='col-md-8'>
                            <h4 className=''>FA NJERI NDIRANGU</h4>
                            <h6 className=''>CO-FOUNDER AND CFO</h6>
                            <p style={{lineHeight:"1.2em"}}>
                            Njeri embarked on her professional journey as an <br/> investment advisor, and in 16 years, she’s left an indelible <br/> mark in the financial markets and investment advisory <br/> landscape.</p>

                            <p style={{lineHeight:"1.2em"}} className='mt-2'>                            
                            Her expertise spans across retail, insurance, fund <br/> management, strategy planning and corporate business <br/> development. </p>
                            <p style={{lineHeight:"1.2em"}} className='mt-2'>                            
                            
                            Njeri holds an MBA in Marketing and various certifications. <br/> She is a registered member of various professional <br/>
                            bodies including ICIFA, CISI and is a certified board trustee <br/> serving in a number of boards.
                            </p>

                        </div>      
                    </div>
                </div>
            </div>
            </div>

            {/* Nduta modal */}
            <div class="modal team_modal fade" id="nduta" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg w-100">
                <div class="modal-content"> 
                    <div class="modal-body ml-auto">
                        <button type="button" class="btn  btn-outline-light ml-auto" data-bs-dismiss="modal">
                            <img
                                src='./assets/images/close.svg'
                                alt='close'
                                style={{width:"1em"}}></img>
                        </button>
                      
                    </div>                   
                    <div class="modal-body row container mb-5">
                        <div className='col-md-4'>
                            <img src='./assets/images/nduta2.png' alt='pentagon'>
                            </img>
                        </div>                  
                        <div className='col-md-8'>
                            <h4 className=''>PATROSE NDUTA</h4>
                            <h6 className=''>CO-FOUNDER AND COO</h6>
                            <p style={{lineHeight:"1.2em"}}>
                            Patrose is a dedicated and seasoned C-Suite executive <br/> with a remarkable 16-year track record in the dynamic
                            <br/> landscape of the Kenyan Financial Markets. Her expertise <br/> spans across various sectors, including fund <br/> management, insurance and real estate. </p>

                            <p style={{lineHeight:"1.2em"}} className='mt-2'>
                            
                            She is highly skilled in diverse areas, including client <br/> services, sales management, distribution management, <br/> training, and project management.</p>
                            <p style={{lineHeight:"1.2em"}} className='mt-2'>
                            
                            Patrose holds a Bachelor’s degree in Business <br/> Administration and she is a registered Investment advisor <br/> and a member of CISI
                            </p>

                        </div>      
                    </div>
                </div>
            </div>
            </div>

            {/* Nduta modal */}
            <div class="modal team_modal fade" id="grace" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg w-100">
                <div class="modal-content"> 
                    <div class="modal-body ml-auto">
                        <button type="button" class="btn  btn-outline-light ml-auto" data-bs-dismiss="modal">
                            <img
                                src='./assets/images/close.svg'
                                alt='close'
                                style={{width:"1em"}}></img>
                        </button>
                      
                    </div>                   
                    <div class="modal-body row container mb-5">
                        <div className='col-md-4'>
                            <img src='./assets/images/grace2.png' alt='pentagon'>
                            </img>
                        </div>                  
                        <div className='col-md-8'>
                            <h4 className=''>GRACE MURIUKI</h4>
                            <h6 className=''>PATNERSHIPS LEAD</h6>
                            <p style={{lineHeight:"1.2em"}}>
                            Grace is a seasoned professional with over 10 years of <br/> experience in investments, insurance, and brokerage. She <br/> specializes in customer relationship management, wealth <br/> management, B2B sales, alliances, and partnerships.
                            <br/></p>

                            <p className="mt-2" style={{lineHeight:"1.2em"}}>
                            She is responsible for strategic partnerships, managing <br/> affiliates, and overseeing key accounts.</p>

                            <p className="mt-2" style={{lineHeight:"1.2em"}}>
                            
                            Grace is an IT engineering professional and a certified <br/> insurance agent.
                            </p>
                                

                        </div>      
                    </div>
                </div>
            </div>
            </div>




        </div>
    )
}

export default TeamModal;
