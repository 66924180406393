import React, { useState ,useEffect} from 'react'
import MyCart from './MyCart';
import { Link, useNavigate } from 'react-router-dom';
import TransactionHistory from './TransactionHistory';
import Invoice from './Invoice';
import Courses from './Courses';
import Account from './Account';
import PackageListing from './PackageListing';
import { ToastContainer } from "react-toastify";
import { getFromLocalStorage } from '../../utils/local-storage';
import { FaBars,FaTimes } from 'react-icons/fa';
import MyCourses from './MyCourses';
import { useDispatch, useSelector } from 'react-redux';
import { accountDetails } from '../../store/users.store';
import { courses, userCourses } from '../../store/courses.store';
import { getListPackages, getPackageDetails } from '../../store/packages.store';
import { userInvoices } from '../../store/invoicing.store';


const Dashboard = () => {
    const navigate = useNavigate();
    const token = getFromLocalStorage('token') //Getting the user token
    const [collapsed, setCollapsed] = useState(false)
    const [small,setSmall] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(screenWidth <= 768);
    const dispatch = useDispatch();
    const active_route = useSelector((state)=>state?.users?.active_route?.active_route)
    const [active, setActive] = useState("account");


    const handleMenuItemClicked = (route) => {
        //setting the active route
        dispatch({
            type: "active_route",
            payload: {"active_route":route}
        })

        console.log("The route is", route)

        // calling the api based on( the route
        if(route == "account"){
            dispatch(accountDetails())
        }else if(route == "interests"){
            dispatch(courses())
        }else if(route == "memberships"){
            dispatch(getListPackages())
        }else if(route == "my_courses"){
            dispatch(userCourses())
        }else if(route == "transactions"){
            dispatch(userInvoices(1))
        }
    }


    useEffect(() => {
        // Function to update screenWidth and isMobile
        const updateScreenWidth = () => {
          const newWidth = window.innerWidth;
          setScreenWidth(newWidth);
          setIsMobile(newWidth <= 768);
        };

        if(isMobile){
            setSmall(true)
            console.log("It is mobile")
        }
    
        // Attach event listener for window resize
        window.addEventListener('resize', updateScreenWidth);
    
        // Call the update function once to get the initial screen width and set isMobile
        updateScreenWidth();
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', updateScreenWidth);
        };
      }, []);

    return (
        <>
            {token ? (
                <div className='dashboard'>
                    <ToastContainer />
                    <div className={`sidebar  ${small ? "sidebar_sm":""}`} id="sidebar1"
                    style={{zIndex:"1000"}}
                    >

                        <div class="offcanvas offcanvas-start  show" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                            <div class="offcanvas-header">
                                <img
                                    id="reverse_logo"
                                    src='./assets/images/reverse_logo.png'
                                    alt='Ujani logo'
                                    style={{
                                        width: "7em",
                                        color: "white"
                                    }}>
                                </img>

                                {/* <FaBars className='toggler_img_off'  onClick={()=>setSmall(!small)}     size={30} /> */}

                                {small ? (
                                    <FaTimes className='toggler_img_off'  onClick={() => setSmall(!small)} size={20} />
                                ) : (
                                    <FaBars className='toggler_img_off'  onClick={() => setSmall(!small)} size={25} />
                                )}

                            </div>
                            <div class="offcanvas-body">
                                <ul className=''>
                                    <li className=''>
                                        <img
                                            src='./assets/images/dashboard.svg'
                                            alt='Ujani logo'
                                        >
                                        </img>
                                        <Link to="/" >Home</Link>

                                    </li>

                                    <li onClick={()=>handleMenuItemClicked("account")}>
                                        <img
                                            src='./assets/images/my_profile.png'
                                            alt='Ujani logo'
                                        >
                                        </img>
                                        <Link to="" >My Profile</Link>

                                    </li>



                                    <li onClick={() => handleMenuItemClicked("interests")}>
                                        <img
                                            src='./assets/images/my_interests.png'
                                            alt='Ujani logo'
                                        >
                                        </img>
                                        <Link to="" >All Courses</Link>

                                    </li>


                                    <li onClick={() => handleMenuItemClicked("my_courses")}>
                                        <img
                                            src='./assets/images/my_interests.png'
                                            alt='Ujani logo'
                                        >
                                        </img>
                                        <Link to="" >My Courses</Link>

                                    </li>

                                    <li onClick={() => handleMenuItemClicked("memberships")}>
                                        <img
                                            src='./assets/images/memberships.png'
                                            alt='Ujani logo'
                                        >
                                        </img>
                                        <Link to="" >Memberships</Link>

                                    </li>

                                    <li onClick={() => handleMenuItemClicked("transactions")}>
                                        <img
                                            src='./assets/images/transactions.png'
                                            alt='Ujani logo'
                                        >
                                        </img>
                                        <Link to="" >My Invoices</Link>
                                    </li>

                                    <li onClick={() => navigate("/logout")}>
                                        <img
                                            src='./assets/images/my_inbox.png'
                                            alt='Ujani logo'
                                        >
                                        </img>
                                        <Link to="" >Logout</Link>                          
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>    



                              
                    <MyCart active={active} />
                    <TransactionHistory active={active} />
                    <Courses active={active} />
                    <PackageListing active={active} />
                    <Account active={active} />
                    <MyCourses active={active}/>
                    <Invoice active={active}/>
                </div>) : navigate("/auth")}
        </>
    )
}

export default Dashboard;
