import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "../api";
import Notify from "../utils/Notify";

const initialState = {
    loading: false,
    courses:{},
    course:{},
    myCourses:{},
    myCourse:{}
}

//asynchronous function , action , callback
export const courses = createAsyncThunk(
    "courses", async (_, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.getCourses()
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})

export const course = createAsyncThunk(
    "course", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.getCourse(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})

export const userCourses = createAsyncThunk(
    "user-courses", async (_, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.getUserCourses()
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})

export const userCourse = createAsyncThunk(
    "user-course", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.getUserCourse(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})

export const userCourseModule = createAsyncThunk(
    "userCourseModule", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.getUserCourseModules(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})




export const courseCheckout = createAsyncThunk(
    "course_checkout", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.courseCheckout(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})

const coursesSlice = createSlice({
    name: "courses",
    initialState,
    extraReducers: (builder) =>{
        //Courses
        builder.addCase(courses.pending, (state, action)=>{
            state.loading = true;

        })

        builder.addCase(courses.fulfilled, (state, action)=>{
            state.courses = action.payload.results;
            state.loading = false;

            // console.log()

            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }
            
        })

        builder.addCase(courses.rejected, (state, action)=>{
            state.loading = true;

        })


        //Course
        builder.addCase(course.pending, (state, action)=>{
            state.loading = true;

        })

        builder.addCase(course.fulfilled, (state, action)=>{
            state.course = action.payload;
            state.loading = false;

            console.log("The course is", action.payload )

            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }
            
        })

        builder.addCase(course.rejected, (state, action)=>{
            state.loading = false;
        })    


        //My courses
        builder.addCase(userCourses.pending, (state, action)=>{
            state.loading = true;

        })

        builder.addCase(userCourses.fulfilled, (state, action)=>{
            state.myCourses = action.payload.results;
            state.loading = false;

            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }
            
        })

        builder.addCase(userCourses.rejected, (state, action)=>{
            state.loading = true;

        })


        //user course
        builder.addCase(userCourse.pending, (state, action)=>{
            state.loading = true;

        })

        builder.addCase(userCourse.fulfilled, (state, action)=>{
            state.myCourse = action.payload;
            state.loading = false;

            console.log("The course is", action.payload )

            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }
            
        })

        builder.addCase(userCourse.rejected, (state, action)=>{
            state.loading = false;
        })    


        //user course module
        builder.addCase(userCourseModule.pending, (state, action)=>{
            state.loading = true;

        })

        builder.addCase(userCourseModule.fulfilled, (state, action)=>{
            state.myCourseModule = action.payload;
            state.loading = false;

            console.log("The course is", action.payload )

            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }
            
        })

        builder.addCase(userCourseModule.rejected, (state, action)=>{
            state.loading = false;
        }) 
    }
})

export default coursesSlice.reducer
