import { configureStore,combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import usersReducer from "./users.store";
import invoicesReducer from "./invoicing.store";
import packagesReducer from "./packages.store";
import paymentsReducer from "./payments.store";
import coursesReducer from "./courses.store";
import {logout } from './users.store';


// combining reducers
const rootReducer = combineReducers({
  users:usersReducer,
  invoices:invoicesReducer,
  packages:packagesReducer,
  payments:paymentsReducer,
  courses:coursesReducer
});

// const appReducer = combineReducers({
//   users: usersReducer,
//   invoices: invoicesReducer,
//   packages: packagesReducer,
//   payments: paymentsReducer,
//   courses: coursesReducer,
// });

// const rootReducer = (state, action) => {
//   if (action.type === 'logout') {
//     // If the logout action is dispatched, return the initial state of each reducer
//     return appReducer(undefined, action);
//   }

//   return appReducer(state, action);
// };

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
