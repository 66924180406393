import React from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const MyCart = (props) => {
    const active = props;
    const navigate = useNavigate();
    return (
        <div className={`my_cart ${props.active == "my-cart" ? "" :"d-none"}`} >
            <h2 className='text-center mt-3 text-dark'>My Cart</h2>
            <div className='d-flex mt-3'>
                <nav aria-label="breadcrumb" className='text-center mx-auto'>
                    <ol class="breadcrumb">                    
                        <li class="breadcrumb-item"><Link to="/dashboard" className=''>Dashboard</Link></li>
                        <li class="breadcrumb-item active">Shopping Cart</li>
                    </ol>
                </nav>
            </div>
 

            <div className='cart_items mt-4'>
                <ul className=''>


                <li className='cart_item mt-2'>
                    <div className='card mx-1 container' style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <div className='row'>
                        <div className='col-4'>
                            {/* <img
                            // src='../assets/images/home_bg.png'
                            alt='Ujani logo'
                            className='card-img'
                            /> */}
                        </div>
                        <div className='col-8'>
                            <div className='card-body'>
                            <p className='mb-0'>
                                <img
                                    src='../assets/images/star.png'
                                    alt='Ujani logo'
                                    // className='card-img'
                                    width='10em'
                                    />
                                      <img
                                    src='../assets/images/star.png'
                                    alt='Ujani logo'
                                    // className='card-img'
                                    width='10em'
                                    />
                                      <img
                                    src='../assets/images/star.png'
                                    alt='Ujani logo'
                                    // className='card-img'
                                    width='10em'
                                    />
                                      <img
                                    src='../assets/images/star.png'
                                    alt='Ujani logo'
                                    className='mr-1'
                                    width='10em'
                                    />
                                4.6<span className='grey'>
                                  
                                    
                                    
                                    (120 reviews)</span></p>
                            <h6 className='card-title'><strong>Ujani Achiever Membership</strong></h6>
                            <p className='card-text sh'>
                                KSH 10,000 <span className='grey' style={{textDecoration:"line-through"}}>KSH 15,000</span>
                            </p>
                            <small className='card-text status px-3 py-1 rounded-pill'>Pending</small>
                            </div>
                        </div>
                        </div>
                    </div>
                    </li>


                    <li className='cart_item mt-2'>
                    <div className='card  mx-1 container' style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <div className='row'>
                        <div className='col-4'>
                            {/* <img
                            // src='../assets/images/home_bg.png'
                            alt='Ujani logo'
                            className='card-img'
                            /> */}
                        </div>
                        <div className='col-8'>
                            <div className='card-body'>
                            <p className='mb-0'>
                            <img
                                    src='../assets/images/star.png'
                                    alt='Ujani logo'
                                    // className='card-img'
                                    width='10em'
                                    />
                                      <img
                                    src='../assets/images/star.png'
                                    alt='Ujani logo'
                                    // className='card-img'
                                    width='10em'
                                    />
                                      <img
                                    src='../assets/images/star.png'
                                    alt='Ujani logo'
                                    // className='card-img'
                                    width='10em'
                                    />
                                      <img
                                    src='../assets/images/star.png'
                                    alt='Ujani logo'
                                    // className='card-img'
                                    width='10em'
                                    className='mr-1'
                                    />
                                4.6<span className='grey'>(12 reviews)</span></p>
                            <h6 className='card-title'><strong>Financial Wellness Webinar</strong></h6>
                            <p className='card-text sh'>
                                KSH 10,000 <span className='grey'></span>
                            </p>
                            <small className='card-text status px-3 py-1 rounded-pill'>Pending</small>
                            </div>
                        </div>
                        </div>
                    </div>
                    </li>

                </ul>

            </div>
            
            <div className='order_summary p-4'>
                <h3><strong>Order Summary</strong></h3>
                <p><span className='start' style={{color:"#6E7485"}}>Subtotal</span> <strong className='end'>Ksh 15,000</strong></p>
                <p><span className='start' style={{color:"#6E7485"}}>Limited Offer</span> <strong className='end'>Ksh 5,000</strong></p> 
                                    <hr/>
                <p><span className='start'>Total:</span> <strong className='end h2 text-dark'>Ksh 10,000</strong></p>
                <button className='btn w-100 rounded-pill' onClick={()=>navigate("/dashboard-checkout")}>Checkout</button>
            </div>

        </div>
    )
}

export default MyCart;
