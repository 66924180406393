import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { loginUser } from '../../store/users.store';
import { useState } from 'react';
import {useSelector,useDispatch} from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer } from "react-toastify";
import { getFromLocalStorage } from '../../utils/local-storage';
import Loader from '../../utils/Loader';
import { FaArrowCircleLeft} from 'react-icons/fa';


const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state)=>state.users.loading);
    const token = getFromLocalStorage('token') //Getting the user token


    useEffect(()=>{
    //   localStorage.clear();   
    },[])

    const handleFormSubmit = (values, {setSubmitting}) =>{
        dispatch(loginUser(values)).then((result)=>{
          if(result?.payload?.token){    
            // navigate("/dashboard")      
            window.location.pathname = "/dashboard";  
          }        
        })
        setSubmitting(false)
    }



    return (
        <div className='auth'>
            <div className='container'>
                <div className='row  login d-flex justify-content-center'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                        <div className='login_form mx-auto p-4 bg-light'>
                            <Formik
                             initialValues={{
                                email:"",
                                password:""
                              }}
              
                              onSubmit={handleFormSubmit}                            
                            >
                                <Form>
                                <FaArrowCircleLeft color='green' onClick={()=>navigate(-1)} size={20}/> 
                                    <h4 className='text-center'><strong className=''>Welcome</strong></h4>
                                    <p className='text-center'>Log in to continue to your account</p>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1"><strong>Email</strong></label>
                                        <Field type="email" class="form-control" name="email" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your Email Address" required/>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputPassword1"><strong>Password</strong></label>
                                        <Field type="password" class="form-control" name="password" id="exampleInputPassword1" placeholder="Enter Your Password" required/>
                                    </div>
                                    <p className='text-end mt-3'><Link to={"/auth-recover"}>Forgot Password?</Link></p>

                                    <div className='mx-auto text-center mt-2'>{loading ? <Loader /> : ""} </div>
                                    <button type="submit" disabled={loading} class="btn btn-success w-100" >Log In</button>
                                </Form>
                            </Formik>
                            <p className='text-center mt-1'><Link to={"/auth-register"}>Don't have an account? Sign In</Link></p>


                        </div>
                    </div>
                    <div className='col-md-3'></div>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}

export default Login;
