import React, { useEffect, useState } from 'react'
import { accountDetails } from '../../store/users.store';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';

const Account = (props) => {

    const dispatch = useDispatch();
    // useEffect(() => {
    //     if(active_route == "account"){
    //         dispatch(accountDetails());
    //     }
    // }, []);

    const user_details = useSelector((state)=>state?.users.accountDetails)
    const active_route = useSelector((state)=>state?.users?.active_route?.active_route)
    



    return (
        <div className={`account ${active_route == "account" ? "" :"d-none"}`}>
            <h2 className='text-center mt-3'><strong>My Account</strong></h2>
            <div className='d-flex mt-3'>
                <nav aria-label="breadcrumb" className='text-center mx-auto'>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to="/dashboard" className=''>Dashboard</Link></li>
                    </ol>
                </nav>
            </div>


            <div className='tabs'>
                <Tabs defaultActiveKey="dashboard" className="mx-auto p-1">
                    <Tab eventKey="dashboard" title="Account Details">
                        {user_details && <div className='account_Details p-2'>
                            <p><strong>First Name </strong><br/>
                            <small className='text-muted'>{user_details.first_name}</small></p>

                            <p><strong>Last Name </strong><br/>
                            <small className='text-muted'> {user_details.surname}</small></p>

                            <p><strong>Email </strong><br/>
                            <small className='text-muted'> {user_details.email}</small></p>

                            <p><strong>Phone Number </strong><br/>
                            <small className='text-muted'>{user_details.phone_number}</small></p>

                            <button className='btn  w-50 mt-3'>Edit</button>

                        </div>}
                    </Tab>
                    {/* <Tab eventKey="my-cart" title="Security">
                        <div className='security'>
                            <p>Password</p>
                            <small>******</small>
                            <p>Two Step verification</p>
                            <small>Activated for 07******125</small>

                            <p>Chnage Password</p>

                            <button className='btn btn-outline-primary'>Edit</button>
                        </div>
                    </Tab> */}
                    {/* <Tab eventKey="checkout" title="Payments">
                        <div className='payments'>

                        <button className='btn btn-outline-primary'>Edit</button>
                        </div>
                    </Tab> */}
                </Tabs>

            </div>

        </div>
    )
}

export default Account;
