import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { packageCheckout, getPackageDetails } from '../../store/packages.store';
import { useState } from 'react';
import {useSelector,useDispatch} from "react-redux";
import { ToastContainer } from "react-toastify";
import { getFromLocalStorage } from '../../utils/local-storage';

const PackageModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state)=>state.users.loading);
    const token = getFromLocalStorage('token');

    const package_details = useSelector((state)=>state?.packages?.packageDetail);


    const checkout = (id) =>{
        if(token){
        dispatch(packageCheckout({'id':id})).then((result)=>{
            console.log(result)
          if(result?.payload?.status === "PENDING"){
            navigate("/dashboard")
            dispatch({
                type: "active_route",
                    payload: {"active_route":"transactions"}
                });
          }        
        })
        }else{
            alert("Please log in to subscribe")
            navigate("/auth")
        }

    }


    return (
        <div>
            <div class="modal membership_modal fade" id="explorer" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg w-100">

            {
            package_details ? (
       
                    <div class="modal-content"> 
                        <div class="modal-body ml-auto">
                            <button type="button" class="btn  btn-outline-light ml-auto" data-bs-dismiss="modal">
                                <img
                                    src='./assets/images/close.svg'
                                    alt='close'
                                    style={{width:"1em"}}></img>
                            </button>
                        
                        </div>                   
                        <div class="modal-body row container mb-5">
                            <div className='col-md-4 '>
                                <img src='./assets/images/frame_6.png' alt='pentagon'></img>
                                {/* <h4 className='text-center'>Ujani <br/> Explorer<br/> Membership</h4> */}
                                <h4>{package_details.name}</h4>
                            </div>                  
                            <div className='col-md-8'>
                                <p>Jambo and welcome to {package_details.name}! <br/>
                                 At Ujani, we believe in the power of community to <br/> transform lives and drive financial success. </p>

                                <h4>Membership Benefits</h4>
                                <ul>
                                    {
                                        package_details?.features?.map((feature,index)=>(
                                            <li key={index}>{feature.name} {feature.code}</li>
                                        ))
                                    }
                                </ul>

                                <p><button className={`btn ${package_details.name == "Ujani Explorer Membership" ? 'd-none' : ''}`} 
                                data-bs-dismiss="modal"
                                onClick={()=>checkout("ae4f0a98-71a3-4989-b5cf-1bc167350498")}>Subscribe</button> <small
                                className={`${package_details.name == "Ujani Explorer Membership" ? 'd-none' : ''}`} 
                                >Terms and coditions apply</small></p>
                            </div>      
                        </div>
                    </div>
            ):(<div>Loading....</div>)}
                </div>

            </div>
        </div>
    )
}

export default PackageModal;
