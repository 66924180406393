import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../store/users.store';
import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer } from "react-toastify";
import Loader from '../../utils/Loader';
import { FaArrowCircleLeft } from 'react-icons/fa';


const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.users.loading);

    const handleFormSubmit = (values, { setSubmitting }) => {
        dispatch(resetPassword(values)).then((result) => {
            if (result?.payload?.message === "success") {
                navigate("/auth")
            }
        })
        setSubmitting(false)
    }
    return (
        <div className='auth'>
            <div className='container'>
                <ToastContainer/>
                <div className='row  login d-flex justify-content-center'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                        <div className='login_form mx-auto p-4 bg-light'>
                            <Formik
                            initialValues={{
                                email:"",
                                otp_code:"",
                                password:""
                            }}
                            onSubmit={handleFormSubmit}
                            >
                            <Form>
                            <FaArrowCircleLeft color='green' onClick={()=>navigate(-1)} size={20}/> 

                                <h4 className='text-center'><strong className=''>Reset You Password</strong></h4>
                                <p className='text-center'>Enter OTP sent to your email address</p>
                                <div class="form-group">
                                    <label for="exampleInputEmail1"><strong>Email</strong></label>
                                    <Field type="email" name="email" class="form-control" id="exampleInputEmail1"  placeholder="Email" />
                                    
                                    <label for="otp"><strong>OTP</strong></label>
                                    <Field type="text" name="otp_code" class="form-control" id="otp"  placeholder="OTP" />

                                    <label for="exampleInputEmail1"><strong>New Password</strong></label>
                                    <Field type="password" name="password" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="New Password" />

{/* 
                                    <label for="exampleInputEmail1"><strong>Confirm Password</strong></label>
                                    <Field type="password" name="confirm_password" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Confirm Password" /> */}
                                </div>

                                <div className='mx-auto text-center mt-2'>{loading ? <Loader /> : ""} </div>
                                <button type="submit" disabled={loading} class="btn btn-success w-100">Reset Password</button>
                            </Form>
                            </Formik>

                        </div>
                    </div>
                    <div className='col-md-3'></div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;
