import React, { useDebugValue, useEffect, useState } from 'react'
import { Link ,useNavigate} from 'react-router-dom';
import { courses, courseCheckout} from '../../store/courses.store';
import { useDispatch,useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Loader from '../../utils/Loader';

const Courses = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const active = props.active;
    const course_list = useSelector((state)=>state?.courses?.courses);
    const active_route = useSelector((state)=>state?.users?.active_route?.active_route)
    const [approved,setApproved] = useState(false);
    
    console.log("The course list is ", course_list)
    useEffect (()=>{
        if(active_route == "interests"){
            dispatch(courses())
        }
    },[])

    // const showAlert = () => {
    //     Swal.fire({
    //         title: "Please Confirm To Proceed",
    //         text: "An Invoice will be generated",
    //         icon: "info",
    //         confirmButtonText: "OK",
    //         confirmButtonColor: "#016B3D",
    //       }).then((result) => {
    //         // Check if the user clicked "OK"
    //         if (result.isConfirmed) {
    //           setApproved(true)    
    //         }
    //       });
        
    // }


    const checkout = (id) =>{
        dispatch(courseCheckout({'id':id})).then((result)=>{
        // showAlert() 
        
        if(result?.payload?.status === "PENDING" && approved){
        console.log("Confirmed ....")
        // navigate("/dashboard")
        console.log("Entering the dispatch....")
        dispatch({
            type: "active_route",
                payload: {"active_route":"transactions"}
            });
        }        
        })

        dispatch({
            type: "active_route",
                payload: {"active_route":"transactions"}
            });
    }
    return (
        <div className={`courses my_cart ${active_route == "interests" ? "" :"d-none"}`}>
            <h2 className='text-center mt-3 text-dark'><strong>All Courses</strong></h2>
            <div className='d-flex mt-3'>
                <nav aria-label="breadcrumb" className='text-center mx-auto'>
                    <ol class="breadcrumb">                    
                        <li class="breadcrumb-item"><Link to="/dashboard" className=''>Dashboard</Link></li>
                        <li class="breadcrumb-item active">Courses</li>
                    </ol>
                </nav>
            </div>

 

            <div className='cart_items mt-4'>
                <ul className='mx-2'>
                    {course_list?.length >= 1  ?  course_list?.map((course, index)=>(
                    
                    <li key={index} className='cart_item  card shadow  mt-2'>
                    
                        <div className='row container'>
                            <div className='col-4'>
                                
                            </div>
                            <div className='col-8 text-start'>                            
                                <h6>{course.title}</h6>
                                <p>{course.author?.full_name}</p>
                                <p>{course.amount}</p>
                                <p>
                                    <button className='btn btn-sm mx-2 '
                                    onClick={()=>checkout(course.id)}
                                    >subscribe</button>
                                    <button className='btn btn-sm mx-2 '
                                    onClick={
                                        // ()=>navigate(`/courses/${course.id}`)
                                        ()=>window.location.pathname = `/courses/${course.id}`
                                    }
                                    >View</button>
                                </p>
                            </div>
                        </div>
                        
                    </li>
                    
                    )):((<p><Loader/></p>))}
                </ul>
            </div>
        </div>
    )
}

export default Courses;
