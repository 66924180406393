import React from 'react'
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';


const Footer = () => {
    return (
        
        <div className={`footer ${window.location.pathname.includes("/auth") || 
        window.location.pathname.includes("/courses") || 
        window.location.pathname.includes("/start-course")  ||

        window.location.pathname.includes("/my-courses") || 
        
        window.location.pathname.includes("/dashboard")? 'd-none ': ''}`}>

            <div className='row bg-success p-5' >
                <div className='col-md-4'>
                    <Link class="navbar-brand mr-5 " to="/">
                        <img
                        src='./assets/images/reverse_logo.png'
                        alt='Ujani logo'
                        style={{width:"7em",
                        marginLeft:"5em",
                        color:"white"
                        }}>
                        </img>
                    </Link>
                </div>
                <div className='col-md-8 row'>
                    <div className='col-md-3 col-sm-6'>
                        <ul>
                            <h6 className=''>Quick Links</h6>
                            <li>About Us</li>
                            <li>The Team</li>
                            <li>Memberships</li>
                            <li>Media</li>
                        </ul>
                    </div>
                    <div className='col-md-3 col-sm-6'>
                        <ul>
                            <h6 className=''>Products and Services</h6>
                            <li>FAQs</li>
                            <li>Resources</li>
                            <li>Sign Up</li>
                            <li>Request for callback</li>
                        </ul>
                    </div>
                    <div className='col-md-3 col-sm-6'>
                        <ul>
                            <h6 className=''>Our Socials</h6>
                            <li><a href='https://www.facebook.com/profile.php?id=61554517623867&mibextid=nwBsNb' target='blank'>
                            <img
                    src='./assets/images/facebook.png'
                    alt='Ujani logo'
                    style={{width:"1.3em"
                    }}>
                    </img>Facebook</a></li>
                            <li>
                                <a href='https://www.instagram.com/ujani_ke?igshid=OGQ5ZDc2ODk2ZA==' target='blank'>
                            <img
                    src='./assets/images/instagram.png'
                    alt='Ujani logo'
                    style={{width:"1.3em"
                    }}>
                    </img>
                                Instagram</a></li>
                            <li><a href='https://twitter.com/Ujani_Kenya' target='blank'>
                            <img
                    src='./assets/images/facebook.png'
                    alt='Ujani logo'
                    style={{width:"1.3em"
                    }}>
                    </img>Twitter</a></li>
                            <li><a href='https://www.linkedin.com/company/ujani/' target='blank'>
                            <img
                    src='./assets/images/linkedin.png'
                    alt='Ujani logo'
                    style={{width:"1.3em"
                    }}>
                    </img>Linkedin</a></li>
                            <li>
                            <img
                    src='./assets/images/tiktok.png'
                    alt='Ujani logo'
                    style={{width:"1.3em"
                    }}>
                    </img>Tiktok</li>
                        </ul>
                    </div>
                    <div className='col-md-3'>
                        <ul>
                            <h6 className=''>Get In Touch</h6>
                            <li>
                            <img
                    src='./assets/images/email.png'
                    alt='Ujani logo'
                    style={{width:"1.3em"
                    }}>
                    </img><span className='email'>ujani@finvantage.co.ke</span></li>
                            <li>
                            <img
                    src='./assets/images/call.svg'
                    alt='Ujani logo'
                    style={{width:"1.3em"
                    }}>
                    </img>0769260000</li>
                        </ul>
                    </div>

                </div>


            </div>

            <div className='row copyright text-light'>
                <p className='ml-5 my-2'>
                <img
                    src='./assets/images/Vector.png'
                    alt='Ujani logo'
                    style={{width:"1em",
                    marginRight:".5em"
                    }}>
                    </img>                    
                    <span className='py-2'>Copyright 2023 All rights reserved</span></p>

            </div>
        </div>
    )
}

export default Footer;