import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { loginUser } from '../../store/users.store';
import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer } from "react-toastify";
import Loader from '../../utils/Loader';
import { FaArrowCircleLeft} from 'react-icons/fa';



const Verify = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.users.loading);

    const handleFormSubmit = (values, { setSubmitting }) => {
        dispatch(loginUser(values)).then((result) => {
            if (result?.payload?.success === true) {
                navigate("/")
            }
        })
        setSubmitting(false)
    }
    return (
        <div className='auth'>
            <div className='container'>
                <div className='row  login d-flex justify-content-center'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                        <div className='login_form mx-auto p-4 bg-light'>
                            <Formik
                                initialValues={{
                                    
                                }}
                            >
                            <Form>
                            <FaArrowCircleLeft color='green' onClick={()=>navigate(-1)} size={20}/> 

                                <h4 className='text-center'><strong className=''>Account Verification</strong></h4>
                                <p className='text-center'>Enter the one time password sent to the email address provided</p>



                                <div class="form-group verification-code-group  d-flex justify-content-center">

                                    <div class="verification-code-inputs">
                                        <Field type="text" name="dig1" class="form-control" id="verificationCode1" maxlength="1" placeholder="" required/>
                                        <Field type="text" name="dig2" class="form-control" id="verificationCode2" maxlength="1" placeholder="" required/>
                                        <Field type="text" name="dig3" class="form-control" id="verificationCode3" maxlength="1" placeholder="" required/>
                                        <Field type="text" name="dig4" class="form-control" id="verificationCode4" maxlength="1" placeholder="" required/>
                                    </div>
                                </div>

                                <div className='mx-auto text-center mt-2'>{loading ? <Loader /> : ""} </div>
                                <button type="submit" disabled={loading} class="btn btn-success w-100">Reset Password</button>
                                <p className='text-center mt-3'>Didn't receive one time password?<Link>Resend</Link></p>
                            </Form>
                            </Formik>

                        </div>
                    </div>
                    <div className='col-md-3'></div>
                </div>
            </div>
        </div>
    )
}

export default Verify;
