import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "../api";
import {setLocalStorage, getFromLocalStorage} from "../utils/local-storage";
import Notify from "../utils/Notify";

const initialState = {
    loading: false,
    userInvoices:{},
    invoiceDetails:{},
    invoice_id:""
}

//asynchronous function , action , callback
export const userInvoices = createAsyncThunk(
    "invoicing", async (page, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.userInvoices(page)
        } catch (e) {
            console.log("The error is ", e)
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})

export const invoiceDetails = createAsyncThunk(
    "invoicing_details", async (id, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.invoiceDetails(id)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})

const invoiceSlice = createSlice({
    name: "invoices",
    initialState,
    extraReducers: (builder) =>{
        builder.addCase('invoice_id', (state, action) => {
            state.invoice_id = action?.payload
        })
        //user invoices
        builder.addCase(userInvoices.pending, (state, action)=>{
            state.loading = true;

        })

        builder.addCase(userInvoices.fulfilled, (state, action)=>{
            console.log("User invoices received ....", action.payload);
            state.userInvoices = action.payload;
            state.loading = false;

            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }
            
        })

        builder.addCase(userInvoices.rejected, (state, action)=>{
            state.loading = false;
            console.log("Reason for rejection ", action)
            
        })


        //invoice details
        builder.addCase(invoiceDetails.pending, (state, action)=>{
            state.loading = true;


        })

        builder.addCase(invoiceDetails.fulfilled, (state, action)=>{
            state.loading = false;
            state.invoiceDetails = action?.payload
            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }

            
        })

        builder.addCase(invoiceDetails.rejected, (state, action)=>{
            state.loading = false
            
        })
        
    }
})

export default invoiceSlice.reducer
