import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';


const TransactionDetails = () => {
    const navigate = useNavigate;
  

    return (
        <div className='transaction_details'>
            <h1 className='text-center mt-3'>Transaction Details</h1>
            <div className='d-flex mt-3'>
                <nav aria-label="breadcrumb" className='text-center mx-auto'>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li class="breadcrumb-item active"><Link to="/dashboard">Transaction History</Link></li>
                    </ol>
                </nav>
            </div>

            <div className='p-3'>
                <div className='payment'>
                    <p>Payment Method</p>
                </div>

                <div className='mpesa'>
                    <p><span className=''>
                        <img
                            src='../assets/images/mpesa.png'
                            alt='Ujani logo'
                            // className='card-img'
                            width='90em'
                        />
                        <span className='ml-3'
                            style={{
                                fontSize: "11px"
                            }}
                        >PHONE-NUMBER</span>
                    </span> <button className='btn btn-success '><strong>Change</strong></button></p>
                    <p className='text-center d-flex justify-content-center'><strong>0720-345793</strong></p>
                </div>

                <div className='upgrade'>
                    <p><span className='start'>Current Plan Summary</span> <button className='btn btn-success end'><strong>Upgrade</strong></button></p>
                </div>
            </div>


            <div class="table-responsive mt-5">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">PLAN NAME</th>
                            <th scope="col">BILLING CYCLE</th>
                            <th scope="col">PLAN COST</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr className=''>
                            <td>Ujani Achiever Membership</td>
                            <td>Monthly</td>
                            <td>Ksh 10,000</td>
                        </tr>

                    </tbody>
                </table>
            </div>


            <div className='px-5'>
                <button className='btn mt-5 w-100 rounded-pill'>Dashboard</button>
            </div>

        </div>
    )
}

export default TransactionDetails;
