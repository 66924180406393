import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "../api";
import {setLocalStorage, getFromLocalStorage} from "../utils/local-storage";
import Notify from "../utils/Notify";

const initialState = {
    loading: false,
    packages:{},
    packageDetail:{}
}

//asynchronous function , action , callback
export const getListPackages = createAsyncThunk(
    "getListPackages", async (_, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.getListPackages()
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})


export const getPackageDetails = createAsyncThunk(
    "getPackageDetails", async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.getPackageDetails(data)
        } catch (e) {
            Notify({ status: 421, message: e })
            console.log("The error is", e)
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})


export const packageCheckout = createAsyncThunk(
    "packages/checkout", async (id, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.packageCheckout(id)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})


export const packageCategories = createAsyncThunk(
    "packageCategories", async (_, thunkAPI) => {
        const state = thunkAPI.getState()
        try {            
            return await api.login(state?.login_form)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
    }      
})


const packageSlice = createSlice({
    name: "packages",
    initialState,
    extraReducers: (builder) =>{
        //lits package details
        builder.addCase(getListPackages.pending, (state, action)=>{
            state.loading = true

        })

        builder.addCase(getListPackages.fulfilled, (state, action)=>{
            state.loading = false
            state.packages = action.payload
            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }
            
        })

        builder.addCase(getListPackages.rejected, (state, action)=>{
            state.loading = false
            
        })

        //Package checkout
        builder.addCase(packageCheckout.pending, (state, action)=>{
            state.loading = true

        })

        builder.addCase(packageCheckout.fulfilled, (state, action)=>{
            state.loading = false
            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }
            
        })

        builder.addCase(packageCheckout.rejected, (state, action)=>{
            state.loading = false
            
        })


        //Package categories
        builder.addCase(packageCategories.pending, (state, action)=>{
            state.loading = true

        })

        builder.addCase(packageCategories.fulfilled, (state, action)=>{
            state.loading = false
            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }
            
        })

        builder.addCase(packageCategories.rejected, (state, action)=>{
            state.loading = false
            
        })


        //Package details
        builder.addCase(getPackageDetails.pending, (state, action)=>{
        state.loading = true

        })

        builder.addCase(getPackageDetails.fulfilled, (state, action)=>{
            state.loading = false
            state.packageDetail = action.payload
            if (action?.payload?.success === false) {
                Notify({ status: 421, message: action?.payload?.errors })
            } else if (action?.payload?.success === true) {
                Notify({ status: 200, message: action?.payload?.message })
            }
            
        })

        builder.addCase(getPackageDetails.rejected, (state, action)=>{
            state.loading = false
            console.log("Package details rejected ....",action)
            
        })
        
    }
})

export default packageSlice.reducer
